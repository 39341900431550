<template>
  <div>
    <Combobox
      :value="modelValue"
      @update:model-value="updateModelValue"
      :default-value="defaultValue"
      nullable
      v-slot="{ open }"
    >
      <div class="tw-relative">
        <div
          class="tw-w-full tw-cursor-default tw-rounded-md tw-bg-white tw-text-left tw-text-black tw-outline-0 focus:tw-outline-none focus-visible:tw-border-brand dark:tw-border-white dark:tw-bg-grey21 dark:tw-text-white"
          :class="{
            'tw-shadow-md': !labelOnTop,
          }"
        >
          <ComboboxLabel
            class="px-1 tw-text-black tw-backdrop-blur-3xl dark:tw-text-white"
            :class="{
              'tw-text-brand dark:tw-text-brand': isFocused,
              'tw-transition-all tw-ease-in': !labelOnTop,
              'tw-absolute tw-left-3 tw-top-3 tw-bg-transparent':
                !labelOnTop && shouldShowLabelOnInput,
              'tw-absolute tw-left-[0.4rem] tw-top-[-0.5rem] tw-z-10  tw-p-0.5 tw-text-xs ':
                !labelOnTop && !shouldShowLabelOnInput,
            }"
          >
            {{ labelOrPlaceholder }}
          </ComboboxLabel>
          <ComboboxInput
            class="tw-w-full tw-rounded-md tw-border tw-border-greyd2 tw-bg-inherit tw-py-[10px] tw-pl-3 tw-pr-10 tw-text-sm tw-leading-5 tw-text-black tw-outline-none tw-outline-offset-0 focus-visible:tw-border-brand dark:tw-bg-grey21 dark:tw-text-white"
            :displayValue="(option:number|string) => getOptionLabel(option)"
            @change="query = $event.target.value"
            @focus="isFocused = true"
            @blur="isFocused = false"
          />
          <ComboboxButton
            class="pr-2 tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center"
          >
            <span
              class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2"
            >
              <font-awesome-icon
                icon="icon fa-solid fa-chevron-down"
                class="icon tw-pointer-events-none tw-text-xs tw-transition-transform"
                :class="{
                  'tw-rotate-180': open,
                }"
              />
            </span>
          </ComboboxButton>
        </div>
        <TransitionRoot
          leave="tw-transition tw-ease-in tw-duration-100"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
          @after-leave="query = ''"
        >
          <ComboboxOptions
            class="normalize-background tw-absolute tw-z-50 tw-mt-1 tw-max-h-44 tw-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base tw-shadow-lg focus:tw-outline-none sm:tw-text-sm dark:tw-bg-grey21 dark:tw-shadow-white"
          >
            <div
              v-if="filteredPeople.length === 0 && query !== ''"
              class="tw-relative tw-cursor-default tw-select-none tw-px-4 tw-py-2 tw-text-gray-700"
            >
              {{ $t('common.noResults') }}
            </div>

            <ComboboxOption
              v-for="person in filteredPeople"
              as="template"
              :key="person.value"
              :value="person.value"
              v-slot="{ selected, active }"
            >
              <li
                class="tw-relative tw-cursor-pointer tw-select-none tw-py-2 tw-pl-10 tw-pr-4"
                :class="{
                  'tw-bg-brand tw-text-white': active,
                  'tw-text-gray-900 dark:tw-text-greyc9': !active,
                }"
              >
                <span
                  class="tw-block tw-truncate"
                  :class="{
                    'tw-font-medium': selected,
                    'tw-font-normal': !selected,
                  }"
                >
                  {{ person.label }}
                </span>
                <span
                  v-if="selected"
                  class="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3"
                >
                  <font-awesome-icon
                    icon="icon fa-solid fa-check"
                    class="tw-text-md"
                  />
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>

    <UIError :isError="isError" :error="error" :errorClass="errorClass" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
  ComboboxLabel,
} from '@headlessui/vue'
import type { SelectOption } from '@/types/CommonTypes'
import UIError from '@/components/UI/UIError.vue'

@Component({
  components: {
    UIError,
    ComboboxLabel,
    Combobox,
    ComboboxInput,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
    TransitionRoot,
  },
  emits: ['update:modelValue'],
})
export default class UICombobox extends Vue {
  @Prop({ required: true })
  public modelValue!: SelectOption['value']
  @Prop({ default: [] })
  public options!: SelectOption[]

  @Prop({ required: false }) public label!: string
  @Prop({ required: false }) public placeholder!: string
  @Prop({ required: false }) public labelOnTop!: boolean

  @Prop({ required: false })
  public errorClass!: string
  @Prop({ required: false })
  public error!: string

  @Prop({ required: false })
  public isError!: boolean

  public isFocused: boolean = false
  public query: string = ''

  public get defaultValue(): SelectOption {
    return this.options.length > 0 ? this.options[0] : { label: '', value: '' }
  }

  public updateModelValue(value: SelectOption['value']): void {
    this.$emit('update:modelValue', value)
  }

  public getOptionLabel(value: number | string): string {
    if (!this.modelValue) return ''
    const foundOption = this.options.find((option) => option.value === value)
    return String(foundOption?.label ?? '')
  }

  public get filteredPeople(): SelectOption[] {
    return this.query === ''
      ? this.options
      : this.options.filter((option) => {
          const optionStr = String(option.label).toLowerCase()
          return optionStr.includes(this.query.toLowerCase())
        })
  }

  public get labelOrPlaceholder(): string {
    if (this.shouldShowLabelOnInput) {
      return this.placeholder
    }
    return this.label
  }

  public get shouldShowLabelOnInput(): boolean {
    const isQueryEmpty = this.query !== ''

    const isModelValueValidString =
      typeof this.modelValue === 'string' && this.modelValue !== ''
    const isModelValueValidNumber =
      typeof this.modelValue === 'number' && this.modelValue !== 0
    const hasValidInput = isModelValueValidString || isModelValueValidNumber

    return !isQueryEmpty && !hasValidInput
  }
}
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
</style>
