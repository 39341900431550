export default {
  attributes: {
    attributes: 'Attributes',
    newAttributes: 'New attribute',
    editAttributes: 'Edit attribute',
    activeAttribute: 'Is the attribute active?',
    name: 'Name',
    yes: 'Yes',
    no: 'No',
  },
}
