<template>
  <div v-if="canShow">
    <UIModal
      v-if="isMaintenanceModalVisible"
      :show-modal="isMaintenanceModalVisible"
      @close="isMaintenanceModalVisible = false"
    >
      <div class="tw-w-100% tw-h-[1000px] tw-w-full">
        <iframe :src="iframeLink" width="100%" height="100%"></iframe>
      </div>
    </UIModal>
    <div class="menu-title admin">Maintenance</div>
    <div
      class="links"
      v-for="link in menuMaintenance"
      :key="`menu-${link.label}`"
    >
      <a
        v-show="checkPermission(link.permissionName)"
        :href="link.route"
        class="link"
        @click.prevent="link.action"
      >
        <span class="icon">
          <font-awesome-icon :icon="link.icon" />
        </span>
        <TransitionGroup name="links-label" tag="span" class="links-label">
          <span :key="`labelLink2_${link.label}`">{{ link.label }}</span>
        </TransitionGroup>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import UIModal from '@/components/UI/UIModal.vue'
import type { MenuItemActionLink } from '@/types/MenuTypes'
import { environmentsManager, userService } from '@/main'
import type { IMenuItem } from '@/types/UserTypes'

@Component({
  components: { UIModal },
})
export default class DashboardMenuMaintenance extends Vue {
  public isMaintenanceModalVisible: boolean = false

  public get iframeLink(): string {
    const BACKEND_URL = import.meta.env.VITE_API_URL
    return `${BACKEND_URL}horizon/dashboard`
  }
  public async openMaintenanceIframe(): Promise<void> {
    this.isMaintenanceModalVisible = true
  }
  public get menuMaintenance(): MenuItemActionLink[] {
    return [
      {
        icon: 'icon fa-solid fa-cog',
        label: 'Horizon',
        route: this.iframeLink,
        permissionName: 'horizon',
        action: this.openMaintenanceIframe,
      },
    ]
  }

  public get disabledPermission(): boolean {
    return environmentsManager.PERMISSIONS_ENABLED === 'false'
  }
  public get permissions(): IMenuItem {
    return userService.menuItems
  }
  public checkPermission(menuItemName: string): boolean {
    if (this.disabledPermission) return true
    const item = this.permissions[menuItemName]
    if (item) {
      return item.active
    }

    return true
  }

  public get canShow(): boolean {
    return this.menuMaintenance.some((link) => {
      return this.checkPermission(link.permissionName)
    })
  }
}
</script>

<style scoped lang="scss">
:deep(.modal-dialog) {
  --bs-modal-width: 80%;
}

:deep(.modal-body) {
  padding: 0;
}

.menu-title {
  margin: 10px 16px;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.2em;
  text-align: left;
  color: #adb5bd;
  text-transform: uppercase;
  font-weight: 400;

  &.admin {
    border-top: 1px solid #adb5bd;
    padding-top: 15px;
    margin-top: 20px;
  }
}

.links {
  padding: 0 16px;

  .link {
    padding: 12px 16px;
    margin-bottom: 2px;
    color: #fff;
    display: flex;
    font-size: 14px;
    text-decoration: none;
    background: none;
    transition-duration: 0.4s;
    border-radius: 5px;

    &:hover {
      background: #394046;
    }

    &.active {
      background: #545d66;
    }

    :deep(.svg-inline--fa) {
      margin-right: 10px;
      font-size: 22px;
    }

    span {
      line-height: 22px;
      text-decoration: none;
    }

    .links-label {
      margin-left: 10px;
    }
  }
}
</style>
