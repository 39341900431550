export default {
  assets: {
    assets: 'Aktywa',
    riskLevel: 'Poziom ryzyka',
    criticality: 'Krytyczność',
    lowImpact: 'Mały wpływ',
    relevant: 'Istotny',
    important: 'Ważny',
    critical: 'Krytyczny',
    introduced: 'Wprowadzony',
    criticalityNotDetermined: 'Krytyczność nie ustalona',
    duringDevelopment: 'W trakcie tworzenia',
    low: 'Niski',
    acceptable: 'Akceptowalny',
    highRisk: 'Wysokiego ryzyka',
    unacceptable: 'Nieakceptowalny',
    systemCriticality: 'Krytyczność systemu',
    comments: 'Uwagi',
    materialityOfAsset: 'Istotność aktywa',
    newAssetToSystem: 'Dodawanie nowego aktywa do systemu:',
    assetManagementCharter: 'Karta zarządcza dla aktywa',
    generalMetrics: 'Metryka ogólna',
    residualRisks: 'Ryzyka szczątkowe',
    reports: 'Zgłoszenia',
    company: 'Firma',
    unit: 'Jednostka',
    systemOrServer: 'Nazwa systemu / serwera / procesu',
    abbreviatedSystemOrServer:
      'Skrócona nazwa systemu / serwera np. SYS/SER001',
    assetDescription: 'Opis Aktywa',
    typeOfAsset: 'Typ Aktywa',
    technicalOwner: 'Właściciel techniczny',
    substituteTechnicalOwner: 'Zastępca właściciela technicznego',
    businessOwner: 'Właściciel biznesowy',
    substituteBizOwner: 'Zastępca właściciela biz',
    materialAsset: 'Aktywo materialne',
    assetInfo: 'Informacja o aktywie',
    managementAssetInfo: 'Informacja zarządcze dot. aktywa',
    purchaseInPLN: 'Wartość zakupu aktywa w PLN',
    amortizationInPercent: 'Amortyzacja aktywa w procentach',
    monthlyAmortizationInPLN: 'Miesięczna amortyzacja w PLN',
    annualAmortizationInPLN: 'Roczna amortyzacja w PLN',
    currentAssetValueInPLN: 'Bieżąca wartość aktywa w PLN',
    assetLaunchDate: 'Data uruchomienia aktywa',
    assetAmortizationEndDate: 'Data zakończenia amortyzacji',
    assetWithdrawn: 'Czy aktywo zostało wycofane?',
    assetExpirationDate: 'Data wygaszenia aktywa',
    contractNumber: 'Numer umowy',
    contractFor: 'Umowa dla',
    contractSigningDateForAsset: 'Data podpisania umowy dla aktywa',
    personResponsibleForContract: 'Osoba odpowiedzialna za umowę',
    personResponsibleForContractOnSupplierSide:
      'Osoba odpowiedzialna za umowę po stronie dostawcy',
    phoneOfPersonResponsibleOnSupplierSide:
      'Numer telefonu osoby odpowiedzialnej po stronie dostawcy',
    emailOfPersonResponsibleOnSupplierSide:
      'Adres e-mail osoby odpowiedzialnej po stronie dostawcy',
    select: 'Wybierz',
    criticalityForm: 'Formularz krytyczności',
    acceptableMaxDowntimeSLA:
      'Akceptowalny maksymalny czas niedostępności (SLA)',
    probabilityOfOccurrence: 'Prawdopodobieństwo wystąpienia',
    numberOfMailUsers: 'Ilość użytkowników poczty',
    numberOfUsers: 'Ilość użytkowników (klientów)',
    numberOfConnectedSystems: 'Ilość podłączonych systemów/zainstalowanych',
    dependencyOnWANLAN: 'Zależność od WAN/LAN',
    estimatedRecoveryTime: 'Przewidywany czas odtworzenia',
    financialLosses: 'Straty finansowe',
    imageLosses: 'Straty wizerunkowe',
    workWeek: 'Tydzień pracy',
    freeDay: 'Wolny dzień',
    dayServiceRequiredFor8h:
      'Dzień - obsługa systemu wymagana jest przez 8h normalnej pracy',
    nightServiceRequiredFor24h: 'Noc - obsługa systemu wymagana jest przez 24h',
    createdBy: 'Utworzył',
    createdAt: 'Data utworzenia',
    rodoRiskLevel: 'Poziom ryzyka RODO',
    potentialUnitRisk:
      'Potencjalne ryzyko jednostkowe - Identyfikacja potencjalnego ryzyka jednostkowego',
    riskEstimation: 'Szacowanie Ryzyka - zdefiniowanie',
    status: 'Status',
    id: 'Identyfikator',
    submitter: 'Zgłaszający',
    email: 'E-mail',
    asset: 'Aktywo',
    notifications: 'Powiadomienia',
    statuses: {
      1: 'w trakcie tworzenia',
      2: 'krytyczność nie ustalona',
      3: 'wprowadzony',
    },
    types: {
      system: 'System',
      application: 'Aplikacja',
      mainProcess: 'Proces główny',
      subProcess: 'Pod proces',
      person: 'Aktywo osobowe',
      helperProcess: 'Proces pomocniczy',
    },
    withdrawOption: {
      yes: 'Tak',
      no: 'Nie',
    },
  },
}
