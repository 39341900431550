export function getTokenCookie(cookie: string): string {
  const value = `; ${cookie}`
  const parts = value.split(`; token=`)

  if (parts.length === 2) {
    return parts.pop()?.split(';').shift() ?? ''
  } else if (parts.length > 2) {
    // This is a workaround for case where we have multiple tokens in the cookie - i.e. assigning token to multiple domains
    return parts[1].split(';')[0]
  }

  return ''
}
