<template>
  <div class="c-uiTable">
    <div class="tableHeader tw-mb-4 tw-flex tw-flex-wrap tw-justify-between">
      <NumberOfResults
        v-model="numberOfResults"
        @setResults="setResult"
        v-if="withNumberOfResults"
      />
      <slot name="topRightSide" />
    </div>
    <div class="table-responsive">
      <table
        class="dataTable no-footer table"
        style="margin-left: 0px; width: 1502px"
      >
        <thead>
          <tr class="headerRow">
            <th
              v-for="(header, index) in headers"
              :key="`tableHeader-${header.label}-${index}`"
              @click="$emit('sort', header.fill)"
              scope="col"
              class="tw-text-uppercase text-secondary font-weight-bolder dt-center !tw-p-2.5 !tw-leading-5 tw-opacity-70"
              :class="{
                sorting: header.sort,
                noSorting: !header.sort,
                sorting_asc:
                  sortFill === header.fill && sortDirection === 'asc',
                sorting_desc:
                  sortFill === header.fill && sortDirection === 'desc',
              }"
              tabindex="0"
              aria-controls="taskList"
              rowspan="1"
              colspan="1"
              :style="{ width: header.width }"
              aria-sort="ascending"
              aria-label="aktywuj, by posortować kolumnę malejąco"
            >
              {{ header.label }}
            </th>
          </tr>
        </thead>

        <Transition name="fade" mode="out-in">
          <tbody v-if="isLoading">
            <LoaderTableRow />
          </tbody>
          <tbody v-else>
            <slot></slot>
          </tbody>
        </Transition>
      </table>
    </div>
    <div class="tw-mt-4">
      <PositionsWithPagination
        :number-of-all-results="numberOfAllResults"
        :number-of-results-per-page="numberOfResults"
        v-if="withPagination"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import type { ITableHeader } from '@/types/TableTypes'
import NumberOfResults from '@/components/common/NumberOfResults.vue'
import PositionsWithPagination from '@/components/common/PositionsWithPagination.vue'
import UIButton from '@/components/UI/UIButton.vue'
import UIInput from '@/components/UI/UIInput.vue'
import UICheckbox from '@/components/UI/UICheckbox.vue'
import Filters from '@/components/views/tasks/Filters.vue'
import LoaderModal from '@/components/common/LoaderModal.vue'
import LoaderTableRow from '@/components/common/LoaderTableRow.vue'

@Component({
  components: {
    LoaderTableRow,
    LoaderModal,
    Filters,
    UICheckbox,
    UIInput,
    NumberOfResults,
    PositionsWithPagination,
    UIButton,
  },
})
export default class UITable extends Vue {
  @Prop({ required: true })
  public headers!: ITableHeader[]

  @Prop({ required: false, default: '' })
  public sortFill!: string

  @Prop({ required: false, default: '' })
  public sortDirection!: string

  @Prop({ required: false })
  public numberOfAllResults!: number

  @Prop({ required: false, default: false })
  public withPagination!: boolean

  @Prop({ required: false, default: false })
  public withNumberOfResults!: boolean

  @Prop({ default: false })
  public isLoading!: boolean

  public numberOfResults: number = 25

  public setResult(results: number): void {
    this.numberOfResults = results
  }
}
</script>

<style lang="scss" scoped>
.dataTable {
  margin-bottom: 20px;
  width: 100% !important;
  th {
    color: var(--header-color) !important;
    font-size: 14px !important;
    text-transform: none !important;
    opacity: 1 !important;
    text-align: left !important;
    vertical-align: top !important;
    line-height: 34px;
    padding: 10px 25px 10px 10px !important;
  }
}
.dataTable.no-footer {
  margin-bottom: 0;
}
.dataTables_scrollBody {
  border: 0 !important;
}
.dataTables_length {
  margin-bottom: 20px;
  label {
    color: #000 !important;
  }
  select {
    height: 38px;
    border: 1px solid #d2d6da !important;
    margin-left: 10px;
    margin-right: 10px;
  }
}

table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:before {
  bottom: 50%;
  content: '▲';
}

table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_desc_disabled:before,
table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
  position: absolute;
  display: block;
  opacity: 0.125;
  right: 10px;
  line-height: 9px;
  font-size: 0.8em;
}

table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
  top: 50%;
  content: '▼';
}

table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:after {
  opacity: 0.6;
}

th,
tr,
td {
  position: relative;
  cursor: pointer;
}

.headerRow th.noSorting {
  cursor: auto !important;
}

.dataTable tbody :deep(tr):hover {
  background-color: #f3f3f3;
}

.dataTable tbody :deep(tr) {
  cursor: pointer;
  line-height: 40px;
  color: black !important;
}

table.dataTable tbody :deep(td),
table.dataTable thead :deep(th) {
  white-space: initial !important;
}

table.dataTable tbody :deep(td) {
  font-size: 15px !important;
}

@media (min-width: 768px) {
  table.dataTable tbody :deep(td) {
    font-size: 16px !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
