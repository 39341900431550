export default {
  reports: {
    title: 'Raporty',
    download: 'Pobierz',
    add: 'Utwórz raport',
    reportsDefinition: 'Definicje raportów',
    addReport: 'Utwórz raport',
    reportFormat: 'Format raportu',
    orderBy: 'Sortuj według',
    filterByDefinition: 'Filtruj według definicji',
    searchText: 'Szukana fraza',
    selectDefinitionAndFormat: 'Wybierz definicję i format raportu',
  },
}
