export default {
  register: {
    withGoogle: 'Za pomocą Google',
    withEmail: 'Za pomocą adresu email',
    login: 'Zaloguj się',
    create: 'Utwórz konto',
    errorAcceptConditions: 'Musisz zaakceptować warunki korzystania z serwisu',
    success: 'Konto utworzone pomyślnie',
  },
}
