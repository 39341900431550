export default {
  users: {
    systemUsers: 'System users',
    email: 'Email',
    loginAt: 'Login date',
    logoutAt: 'Logout date',
    activated: 'Activated',
    emailVerified: 'Email verified',
    actions: 'Actions',
    resetAuth: 'Reset authentication',
    edit: 'Edit',
    block: 'Block',
    unblock: 'Unblock',
    delete: 'Delete',
    permissions: {
      permissions: 'Permissions',
      dictionaries: 'Dictionaries',
      users: 'Users',
      reports: 'Reports',
      roles: 'Roles',
      reportCategory: 'Report category',
      profile: 'Profile',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      phone: 'Phone',
      companyAffiliation: 'Company affiliation',
      selectDepartament: 'Select departament',
      authorization: {
        title: 'Authorization',
        accountStatus: 'Account status',
        replacementPerson: 'Replacement person',
        active: 'Active',
        blocked: 'Blocked',
      },
    },
  },
}
