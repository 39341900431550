export default {
  profile: {
    settings: {
      accountSettings: {
        title: 'Ustawienia konta',
        emailAddressVerification: 'Weryfikacja adresem email',
        verificationUsingTOTPAuthenticator:
          'Weryfikacja przy użyciu TOTP Authenticator',
        verificationType: 'Rodzaj weryfikacji konta',
        confirmWithEmail:
          'Potwierdź kodem który został wysłany na twojego maila',
        sendEmailAgain: 'Wyślij emaila ponownie',
        confirmWithTOTP: 'Potwierdź kodem z aplikacji autoryzacyjnej',
      },
      changePassword: {
        title: 'Zmień hasło',
        passwordRequirements: 'Wymagania dotyczące hasła',
        followThisGuide:
          'Postępuj zgodnie z tym przewodnikiem, aby uzyskać silne hasło',
        specialChar: 'Jeden znak specjalny',
        minLength: 'Min. 6 znaków',
        oneNumber: 'Jedna cyfra (zalecane dwie)',
        frequentlyChangePassword: 'Częsta zmiana hasła',
        actualizePassword: 'Zaktualizuj hasło',
      },
      defaultCard: {
        title: 'Podstawowe',
      },
      safetyRulesCard: {
        title: 'Kluczowe zasady zachowania bezpieczeństwa informacji',
        title1: 'Zasada adekwatności zabezpieczeń',
        description1:
          'Stosowane zabezpieczenia muszą być adekwatne do zidentyfikowanych zagrożeń.',
        title2: 'Zasada bezpiecznego przetwarzania',
        description2:
          'Przetwarzanie informacji szczególnie chronionych powinno odbywać się wyłącznie w bezpiecznych środowiskach, tj. w wydzielonych systemach informatycznych, zabezpieczonych pomieszczeniach etc.',
        title3: 'Zasada bezpiecznej współpracy z podmiotami zewnętrznymi',
        description3:
          'Dokumenty regulujące współpracę z podmiotami zewnętrznymi (m.in. treść umów i porozumień) zawierają zapisy dot. bezpieczeństwa informacji, w tym klauzule bezpieczeństwa o zachowaniu poufności.',
        title4: 'Zasada segregacji obowiązków i zadań',
        description4:
          'Obowiązki i uprawnienia powinny być tak rozdzielone, aby pojedyncza osoba nie dysponowała pełnią uprawnień do wykonywania zadań w całości.',
        title5: 'Zasada uprawnionego dostępu',
        description5:
          'Korzystanie z aktywów informacyjnych spółki może się odbywać wyłącznie w oparciu o formalne uprawnienia.',
        title6: 'Zasada wiedzy uzasadnionej',
        description6:
          'Personel dysponuje wiedzą o aktywach informacyjnych w ograniczonym zakresie, niezbędnym do realizacji powierzonych mu zadań.',
        title7: 'Zasada czystego biurka',
        description7:
          'W celu wyeliminowania ryzyka przypadkowego lub celowego odczytania informacji, ich skopiowania, zniszczenia lub zmodyfikowania przez osoby nieuprawnione, opuszczając stanowisko pracy należy usunąć z blatu biurka dokumenty, umieszczając je w przeznaczonych do tego celu zabezpieczonych meblach biurowych: np. szafach, szufladach lub sejfach.',
        title8: 'Zasada czystego ekranu',
        description8:
          'Na czas nieobecności dostęp do komputera należy skutecznie zablokować, a po zakończeniu pracy komputer wyłączyć, chyba że musi on pracować w trybie ciągłym. Dodatkowo należy uniemożliwiać wgląd w treści wyświetlane na monitorach osobom nieupoważnionym poprzez odpowiednie ustawienie ekranu lub stosowanie filtrów prywatyzujących.',
        title9: 'Zasada niszczenia dokumentów',
        description9:
          'W trakcie pracy pracownicy, którzy muszą trwale pozbyć się dokumentów, brudnopisów, kopii, powinni je utylizować poprzez użycie np. niszczarki dokumentów. Ważne jest to, że dokumenty, które zawierają chronione informacje firmy, nie powinny znaleźć się w koszu na śmieci. Grozi to tym, że osoba nieupoważniona może mieć do nich wgląd poprzez ich wyjęcie.',
        title10: 'Zasada kontroli dostępu do pomieszczeń',
        description10:
          'Dostęp do pomieszczeń służbowych należy nadzorować za pomocą systemu kontroli dostępu oraz odpowiedniej dystrybucji kluczy. Przy wchodzeniu do siedziby lub pomieszczeń firmy, za pomocą użycia karty dostępu, należy zwracać uwagę, czy osoby nieupoważnione nie próbują dostać się na teren, przechodząc tuż za osobami uprawnionymi do wejścia. Po wejściu do pomieszczeń, otwieranych za pomocą klucza, przed przystąpieniem do pracy należy sprawdzić, czy klucz nie został po zewnętrznej stronie drzwi.',
        title11: 'Zasada bezpiecznego wydruku',
        description11:
          'Podczas korzystania z drukarki należy kontrolować wydruk dokumentów oraz zabierać wydrukowane materiały z drukarki od razu po zakończeniu tego procesu. W przypadku skanowania, po zakończonym procesie, należy niezwłocznie usunąć plik skanu znajdujący się w katalogu sieciowym, a także zabrać skanowany dokument.',
        title12: 'Zasada bezpieczeństwa nośników danych',
        description12:
          "Dane zawarte na pendrive'ach, dyskach USB, płytach CD należy zabezpieczać poprzez szyfrowanie. Dozwolone jest używanie tylko służbowych nośników informacji. W trakcie podróży nośniki i sprzęt informatyczny muszą znajdować się pod stałą kontrolą osoby użytkującej.",
        title13: 'Zasady korzystania z urządzeń mobilnych',
        description13:
          'Nie należy używać urządzeń mobilnych w miejscach publicznych oraz udostępniać ich osobom trzecim. Nie wolno korzystać z nieznanych sieci bezprzewodowych i ogólnodostępnych punktów dostępowych typu hotspot.',
        title14: 'Zasady bezpieczeństwa pomieszczeń biurowych',
        description14:
          'Przed rozpoczęciem pracy należy sprawdzać, czy wszystkie sprzęty w biurze działają prawidłowo. Ostatnia osoba wychodząca z biura zamyka pomieszczenie na klucz. Klucze należy przekazywać ochronie na przechowanie lub wkładać do depozytora kluczy z elektronicznym dostępem. Klucze do biurek i szafek stanowiskowych powinny być pod stałym nadzorem pracowników. Po zakończeniu pracy pracownicy powinni uporządkować swoje stanowisko pracy zgodnie z „Polityką czystego biurka”.',
      },
    },
  },
}
