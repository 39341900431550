<template>
  <div class="c-numberOfNotificationsPerCategory">
    <DashboardBox
      :title="$t('views.dashboard.numberOfNotificationsPerCategory')"
      :is-loading="isLoading"
    >
      <slot>
        <UIGridTable :headers="numberOfNotificationsCategoryHeaders">
          <div
            class="tw-grid tw-grid-cols-2 tw-text-sm xl:tw-text-base"
            v-for="(notification, index) in numberOfNotificationsData"
            :key="`numberOfNotificationsCategory_${index}`"
          >
            <div class="tw-p-2">{{ notification.category_name }}</div>
            <div class="tw-p-2">{{ notification.count }}</div>
          </div>
        </UIGridTable>
      </slot>
    </DashboardBox>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import DashboardBox from '@/components/views/dashboard/DashboardBox.vue'
import UIGridTable from '@/components/UI/UIGridTable.vue'
import type { IWidgetNumberOfTicketsPerCategory } from '@/types/WidgetTypes'

@Component({
  components: {
    DashboardBox,
    UIGridTable,
  },
})
export default class NumberOfNotificationsPerCategory extends Vue {
  @Prop({ required: true })
  public isLoading!: boolean
  public get numberOfNotificationsCategoryHeaders(): string[] {
    return [
      this.$t('views.dashboard.assetCriticality'),
      this.$t('views.dashboard.numberOfNotifications'),
    ]
  }

  @Prop({ required: true })
  public numberOfNotificationsData!: IWidgetNumberOfTicketsPerCategory[]
}
</script>
