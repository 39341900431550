export default {
  notifications: {
    consentsAccepted: 'Zgoda została zaakceptowana pomyślnie',
    consentsAcceptedError: 'Wystąpił błąd podczas akceptacji zgody',
    saveSuccess: 'Zmiany zostały zapisane.',
    unitDeleteSuccess: 'Jednostka została usunięta poprawnie.',

    errors: {
      fillAllFields: 'Uzupełnij wszystkie pola',
      passwordsNotEqual: 'Hasła nie są takie same',
      default: 'Coś poszło nie tak',
      '400': 'Nieprawidłowe dane',
      '401': 'Użytkownik jest nieautoryzowany',
      '403': 'Użytkownik nie ma uprawnień do wykonania tej akcji',
      '404': 'Nie znaleziono zasobu',
      '413': 'Za dużo danych',
      '422': 'Nieprawidłowe dane',
      '500': 'Wystąpił błąd serwera',
    },
  },
}
