<template>
  <div class="c-openNotification">
    <DashboardBox
      :title="$t('views.dashboard.openNotifications')"
      :is-loading="isLoading"
    >
      <slot>
        <div class="tw-px-4">
          <UIChartDoughnut
            :data="openNotificationsData"
            @click="redirectToAssets"
          />
        </div>
      </slot>
    </DashboardBox>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import DashboardBox from '@/components/views/dashboard/DashboardBox.vue'
import UIChartDoughnut from '@/components/UI/UIChartDoughnut.vue'
import type { IChartData } from '@/types/ChartsTypes'
import type { IWidgetOpenTickets } from '@/types/WidgetTypes'

@Component({
  components: {
    UIChartDoughnut,
    DashboardBox,
  },
})
export default class OpenNotifications extends Vue {
  @Prop({ required: true })
  public isLoading!: boolean

  @Prop({ required: true })
  public statistics!: IWidgetOpenTickets[]

  public getCountByName(name: string): number {
    const stat = this.statistics.find(
      (stat) => stat.system_criticality === name
    )
    if (!stat) return 0
    return stat.count
  }

  public get openNotificationsData(): IChartData {
    return {
      labels: [
        this.$t('views.dashboard.lowImpact'),
        this.$t('views.dashboard.relevant'),
        this.$t('views.dashboard.important'),
        this.$t('views.dashboard.critical'),
      ],
      datasets: [
        {
          data: [
            this.getCountByName(this.$t('views.dashboard.lowImpact')),
            this.getCountByName(this.$t('views.dashboard.relevant')),
            this.getCountByName(this.$t('views.dashboard.important')),
            this.getCountByName(this.$t('views.dashboard.critical')),
          ],
          backgroundColor: ['#4eba6f', '#ffd553', '#ff920f', '#f0563e'],
        },
      ],
    }
  }
  public redirectToAssets(): void {
    this.$router.push(`/tickets/?criticality=2`)
  }
}
</script>
