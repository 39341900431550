import { Store, Pinia } from 'pinia-class-component'
import type {
  ICompany,
  ICompanyForm,
  ICompanyUnit,
  ICompanyUnitForm,
} from '@/types/CompaniesTypes'
import type { Nullable } from '@/types/Nullable'
import CompaniesRepository from '@/repositories/CompaniesRepository'

interface ICompaniesStore {
  companies: ICompany[]
  units: ICompanyUnit[]
  company: Nullable<ICompany>
  unit: Nullable<ICompanyUnit>
  isLoading: boolean
  isError: boolean
}

@Store
export default class CompaniesService extends Pinia {
  public store: ICompaniesStore = {
    companies: [],
    units: [],
    unit: null,
    company: null,
    isLoading: false,
    isError: false,
  }

  public get units(): ICompanyUnit[] {
    return this.store.units
  }

  public get companies(): ICompany[] {
    return this.store.companies
  }

  public get company(): Nullable<ICompany> {
    return this.store.company
  }

  public get isLoading(): boolean {
    return this.store.isLoading
  }

  public get isError(): boolean {
    return this.store.isError
  }

  public async loadCompanies(): Promise<void> {
    this.store.isLoading = true
    await CompaniesRepository.getCompanies()
      .then((response) => {
        this.store.companies = response
      })
      .catch(() => {
        this.store.companies = []
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public clearCompany(): void {
    this.store.company = null
  }
  public clearUnits(): void {
    this.store.units = []
  }

  public async loadCompany(id: number): Promise<void> {
    this.store.isLoading = true
    await CompaniesRepository.getCompany(id)
      .then((response) => {
        this.store.company = response
      })
      .catch(() => {
        this.store.company = null
        this.store.isError = true
        throw new Error('Error loading company')
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadUnits(id: number): Promise<void> {
    this.store.isLoading = true
    await CompaniesRepository.getCompanyUnits(id)
      .then((response) => {
        this.store.units = response
      })
      .catch(() => {
        this.store.units = []
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async addCompany(form: ICompanyForm): Promise<void> {
    this.store.isLoading = true
    await CompaniesRepository.addCompany(form)
      .then((response) => {
        this.store.company = response
      })
      .catch(() => {
        this.store.company = null
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async addCompanyUnit(
    idCompany: number,
    form: ICompanyUnitForm
  ): Promise<void> {
    this.store.isLoading = true
    await CompaniesRepository.addCompanyUnit(idCompany, form)
      .then((response) => {
        this.store.unit = response
      })
      .catch(() => {
        this.store.unit = null
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async updateCompany(id: number, form: ICompanyForm): Promise<void> {
    this.store.isLoading = true
    await CompaniesRepository.updateCompany(id, form)
      .then((response) => {
        this.store.company = response
      })
      .catch(() => {
        this.store.company = null
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async updateCompanyUnit(
    id: number,
    idUnit: number,
    form: ICompanyUnitForm
  ): Promise<void> {
    this.store.isLoading = true
    await CompaniesRepository.updateCompanyUnit(id, idUnit, form)
      .then((response) => {
        this.store.unit = response
      })
      .catch(() => {
        this.store.unit = null
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async deleteCompany(id: number): Promise<void> {
    this.store.isLoading = true
    await CompaniesRepository.deleteCompany(id)
      .then((response) => {
        this.store.company = null
        return response
      })
      .catch(() => {
        this.store.company = null
        this.store.isError = true
        return false
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async deleteCompanyUnit(
    companyId: number,
    unitId: number
  ): Promise<void> {
    this.store.isLoading = true
    await CompaniesRepository.deleteCompanyUnit(companyId, unitId)
      .then((response) => {
        this.store.unit = null
        return response
      })
      .catch(() => {
        this.store.unit = null
        this.store.isError = true
        return false
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }
}
