export default {
  parameters: {
    parameters: 'Parameters',
    modalEdit: {
      editParameter: 'Edit parameter',
      notificationSuccess: 'Parameters updated successfully',
      form: {
        name: 'Name',
        weight: 'Weight',
        selectType: 'Select type',
        value: 'Value',
        types: {
          days: 'Days',
          minutes: 'Minutes',
          txt: 'Txt',
        },
      },
      buttons: {
        save: 'Save',
        cancel: 'Cancel',
      },
    },
  },
}
