export default {
  companies: {
    companies: 'Companies',
    company: 'Company: ',
    newCompany: 'New Company',
    name: 'Name',
    companyName: 'Company name',
    street: 'Street',
    nip: 'NIP',
    town: 'Town',
    postcode: 'Postcode',
    phone: 'Phone number',
    newUnit: 'Nowa jednostka',
  },
}
