<template>
  <template v-if="item.children?.length === 0 || item.route.length > 0">
    <a
      :href="item.route"
      class="c-menuItem link"
      v-show="checkPermission(item.permissionName)"
      @click.prevent="handleReroute(item.route)"
    >
      <span class="icon">
        <font-awesome-icon :icon="item.icon" />
      </span>
      <TransitionGroup name="links-label" tag="span" class="links-label">
        <span :key="`labelLink2_${item.label}`" v-if="!show">{{
          item.label
        }}</span>
      </TransitionGroup>
    </a>
  </template>
  <template v-else>
    <div
      class="c-menuItem"
      v-if="isEnabledChildren"
      :class="{ show: show, open: showChildren }"
      @click="showChildren = !showChildren"
    >
      <div role="button" class="link tw-cursor-pointer tw-select-none">
        <span class="icon">
          <font-awesome-icon :icon="item.icon" />
        </span>
        <TransitionGroup name="links-label" tag="span" class="links-label">
          <span :key="`labelLink2_${item.label}`" v-if="!show">{{
            item.label
          }}</span>
        </TransitionGroup>
        <span class="icon arrow tw-ml-auto">
          <font-awesome-icon
            icon="chevron-down"
            class="tw-transition-transform tw-duration-300 tw-ease-in-out"
            :class="{
              'tw-rotate-180': showChildren,
              'tw-rotate-0': !showChildren,
            }"
          />
        </span>
      </div>
      <div class="children">
        <MenuItem
          v-for="child in children"
          :key="`menu-${child.label}`"
          :item="child"
          :show="show"
          @changeRoute="showChildren = false"
        />
      </div>
    </div>
  </template>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import type { MenuLink } from '@/types/MenuTypes'
import type { IMenuItem } from '@/types/UserTypes'
import { environmentsManager, userService } from '@/main'

@Component({})
export default class MenuItem extends Vue {
  @Prop({ default: false }) public item!: MenuLink
  @Prop({ default: false }) public show!: boolean
  public showChildren = false

  public get isEnabledChildren(): boolean {
    return this.children.some((child) => {
      return this.checkPermission(child.permissionName)
    })
  }
  public get children(): MenuLink[] {
    return this.item.children || []
  }

  public get permissions(): IMenuItem {
    return userService.menuItems
  }

  public checkPermission(menuItemName: string): boolean {
    if (this.disabledPermission) return true
    const item = this.permissions[menuItemName]
    if (item) {
      return item.active
    }

    return true
  }

  public get disabledPermission(): boolean {
    return environmentsManager.PERMISSIONS_ENABLED === 'false'
  }

  public async handleReroute(route: string): Promise<void> {
    this.$emit('changeRoute')
    userService.reauth().then(() => {
      this.$router.push(route)
    })
  }
}
</script>
<style scoped lang="scss">
.c-menuItem {
  flex-wrap: wrap;
}

.children {
  width: 100%;
  overflow: hidden;
  max-height: 0px;
  transition-duration: 0.4s;
}
.images-enter-active,
.images-leave-active,
.links-label-enter-active,
.links-label-leave-active {
  transition: all 0.5s ease;
}
.images-enter-from,
.images-leave-to,
.links-label-enter-from,
.links-label-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.images-leave-active,
.links-label-leave-active {
  position: absolute;
}

.links {
  padding: 0 16px;

  .link:not(.show) {
    padding: 12px 16px;
    margin-bottom: 2px;
    color: #fff;
    display: flex;
    font-size: 14px;
    text-decoration: none;
    background: none;
    transition-duration: 0.4s;
    border-radius: 5px;

    &:hover {
      background: #394046;
    }

    &.active {
      background: #545d66;
    }

    :deep(.svg-inline--fa) {
      margin-right: 10px;
      font-size: 22px;
    }

    span {
      line-height: 22px;
      text-decoration: none;
    }

    .links-label {
      margin-left: 10px;
    }
  }
}

.icon {
  width: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  max-height: 15px;
}

.open .children {
  max-height: 500px;
  padding-left: 15px;
}

.show {
  .icon.arrow {
    width: 6px;
    .svg-inline--fa {
      font-size: 16px;
      margin-top: 3px;
    }
  }
}

.show.open {
  .children {
    padding-left: 0;
  }
}
</style>
