<template>
  <div class="c-riskCategory">
    <DashboardBox
      :title="$t('views.dashboard.riskCategory')"
      :is-loading="isLoading"
    >
      <slot>
        <div class="tw-p-4">
          <UIChartBar
            :data="riskCategoryData"
            :custom-options="customOptions"
          />
        </div>
      </slot>
    </DashboardBox>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Setup, Vue } from 'vue-facing-decorator'
import DashboardBox from '@/components/views/dashboard/DashboardBox.vue'
import UIChartBar from '@/components/UI/UIChartBar.vue'
import type { IChartData } from '@/types/ChartsTypes'
import type { ChartOptions } from 'chart.js'
import { type BasicColorMode, useColorMode } from '@vueuse/core'
import type { IWidgetCategoryRisk } from '@/types/WidgetTypes'

@Component({
  components: {
    UIChartBar,
    DashboardBox,
  },
})
export default class RiskCategory extends Vue {
  @Prop({ default: false })
  public isLoading!: boolean

  @Prop({ default: false })
  public categoryRisk!: IWidgetCategoryRisk

  @Setup(() =>
    useColorMode({
      attribute: 'data-bs-theme',
    })
  )
  public mode!: BasicColorMode

  public get customOptions(): ChartOptions<'bar'> {
    return {
      indexAxis: 'y',
      scales: {
        x: {
          stacked: true,
          ticks: {
            font: {
              size: 12,
            },
            color: this.mode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'black', // for dark mode
            autoSkip: false,
          },
        },
        y: {
          stacked: true,
          ticks: {
            font: {
              size: 12,
            },
            color: this.mode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'black', // for dark mode
            autoSkip: false,
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            color: this.mode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'black', // for dark mode
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    }
  }

  public get labels(): string[] {
    const risks = Object.entries(this.categoryRisk)
    return risks.map(([key]) => key)
  }

  public getValues(riskLevel: string): number[] {
    const values: number[] = []
    const risks = Object.entries(this.categoryRisk)
    for (const [value] of risks) {
      const risksValues = Object.entries(value)
      for (const [risk, count] of risksValues) {
        if (risk === riskLevel) {
          values.push(Number(count))
        }
      }
    }
    return values ?? []
  }

  public get riskCategoryData(): IChartData {
    return {
      labels: this.labels,
      datasets: [
        {
          label: this.$t('views.dashboard.low'),
          data: this.getValues(this.$t('views.dashboard.low')),
          backgroundColor: '#4eba6f',
        },
        {
          label: this.$t('views.dashboard.acceptable'),
          data: this.getValues(this.$t('views.dashboard.acceptable')),
          backgroundColor: '#6ac385',
        },
        {
          label: this.$t('views.dashboard.highRisky'),
          data: this.getValues(this.$t('views.dashboard.highRisky')),
          backgroundColor: '#ffd553',
        },
        {
          label: this.$t('views.dashboard.critical'),
          data: this.getValues(this.$t('views.dashboard.critical')),
          backgroundColor: '#ff920f',
        },
        {
          label: this.$t('views.dashboard.unacceptable'),
          data: this.getValues(this.$t('views.dashboard.unacceptable')),
          backgroundColor: '#f0563e',
        },
      ],
    }
  }
}
</script>
