import login from '@/translations/pl/views/login'
import registerSelect from '@/translations/pl/views/registerSelect'
import common from '@/translations/pl/common'
import datatables from '@/translations/pl/datatables'
import register from '@/translations/pl/views/register'
import company from '@/translations/pl/company'
import resetPassword from '@/translations/pl/views/resetPassword'
import profile from '@/translations/pl/views/profile'
import tickets from '@/translations/pl/views/tickets'
import tasks from '@/translations/pl/views/tasks'
import assets from '@/translations/pl/views/assets'
import users from '@/translations/pl/views/users'
import consents from '@/translations/pl/views/consents'
import roles from '@/translations/pl/views/roles'
import parameters from '@/translations/pl/views/parameters'
import attributes from '@/translations/pl/views/attributes'
import companies from '@/translations/pl/views/companies'
import glossaries from '@/translations/pl/views/glossaries'
import dashboard from '@/translations/pl/views/dashboard'
import risk from '@/translations/pl/views/risk'
import sources from '@/translations/pl/views/sources'

import verify from '@/translations/pl/views/verify'
import affairs from '@/translations/pl/views/affairs'
import notifications from '@/translations/pl/views/notifications'
import reports from '@/translations/pl/views/reports'

export default {
  ...company,
  ...common,
  ...datatables,
  views: {
    ...login,
    ...registerSelect,
    ...register,
    ...resetPassword,
    ...profile,
    ...tickets,
    ...tasks,
    ...assets,
    ...users,
    ...consents,
    ...roles,
    ...parameters,
    ...attributes,
    ...companies,
    ...glossaries,
    ...dashboard,
    ...verify,
    ...risk,
    ...affairs,
    ...sources,
    ...notifications,
    ...reports,
  },
}
