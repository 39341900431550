import type { Nullable } from '@/types/Nullable'
import type { ErrorElementsType, IError } from '@/types/ErrorTypes'

export function isError(val: Nullable<string[]>): boolean {
  return Boolean(val && val.length > 0)
}

export function getError(val: Nullable<string[]>): string {
  if (!isError(val) || !val) return ''
  return val.join(', ')
}

export function parseErrors(err: IError): ErrorElementsType {
  const errors = Object.entries(err)
  const transformErrors: ErrorElementsType = {}
  errors.forEach(([key, value]) => {
    let newVal = ''
    if (Array.isArray(value)) {
      // newVal = value.join(',')
      newVal = String(value[0])
    }
    transformErrors[key] = newVal
  })
  return transformErrors
}
