<template>
  <div class="c-riskMap">
    <DashboardBox
      :title="$t('views.dashboard.riskMap')"
      :is-loading="isLoading"
    >
      <slot v-if="!isLoading">
        <div class="tw-w-full tw-p-4">
          <div class="overflow-x-auto mx-auto tw-w-full">
            <table
              class="table-auto text-center border-collapse tw-mx-auto tw-w-full"
            >
              <tbody>
                <tr
                  v-for="(row, rowIndex) in tableContent"
                  :key="`row-${rowIndex}`"
                  class="tw-text-sm xl:tw-text-base"
                >
                  <td
                    v-if="rowIndex === 0"
                    rowspan="6"
                    class="tw-pl-2 tw-font-bold 2xl:tw-pl-10"
                    style="writing-mode: vertical-lr; transform: rotate(180deg)"
                  >
                    {{ $t('views.dashboard.threatLevel') }}
                  </td>
                  <td class="p-2">{{ row.label }}</td>
                  <td
                    v-for="(cell, cellIndex) in row.cells"
                    :key="`riskMap_${rowIndex}_${cellIndex}`"
                    class="border border-white p-2 hover:tw-cursor-pointer hover:tw-opacity-80 hover:tw-duration-300"
                    :style="{ backgroundColor: cell.color, color: 'white' }"
                  >
                    {{ cell.value }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="tw-text-sm xl:tw-text-base">
                  <th class="p-2"></th>
                  <th class="p-2"></th>
                  <th
                    class="p-2 tw-font-normal"
                    v-for="(header, id) in headers"
                    :key="id"
                  >
                    {{ header }}
                  </th>
                </tr>
                <tr>
                  <td></td>
                  <td
                    colspan="6"
                    class="horizontal-text text-center tw-pt-3 tw-text-sm tw-font-bold xl:tw-text-base 2xl:tw-pt-8"
                  >
                    {{ $t('views.dashboard.criticality') }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </slot>
    </DashboardBox>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import DashboardBox from '@/components/views/dashboard/DashboardBox.vue'
import type { IWidgetRiskMap } from '@/types/WidgetTypes'

interface ITableCell {
  value: number
  color: string
}

interface ITableContent {
  label: string
  cells: ITableCell[]
}
@Component({
  components: {
    DashboardBox,
  },
})
export default class RiskMap extends Vue {
  @Prop({ required: true })
  public isLoading!: boolean

  @Prop({ required: true })
  public statistics!: IWidgetRiskMap[]

  public getCountByName(
    system_criticality: string,
    risk_level: string
  ): number {
    const stat = this.statistics.find(
      (stat) =>
        stat.system_criticality === system_criticality &&
        stat.risk_level === risk_level
    )
    if (!stat) return 0
    return stat.count
  }

  public get headers(): string[] {
    try {
      return [
        this.$t('views.dashboard.lowImpact'),
        this.$t('views.dashboard.relevant'),
        this.$t('views.dashboard.important'),
        this.$t('views.dashboard.critical'),
      ]
    } catch (e) {
      return []
    }
  }

  public get risks(): string[] {
    try {
      return [
        this.$t('views.dashboard.unacceptable'),
        this.$t('views.dashboard.critical'),
        this.$t('views.dashboard.highRisky'),
        this.$t('views.dashboard.acceptable'),
        this.$t('views.dashboard.low'),
      ]
    } catch (e) {
      return []
    }
  }

  public get tableContent(): ITableContent[] {
    return [
      {
        label: this.risks[0],
        cells: [
          {
            value: this.getCountByName(this.headers[0], this.risks[0]),
            color: '#fdd84b',
          },
          {
            value: this.getCountByName(this.headers[1], this.risks[0]),
            color: '#ff920f',
          },
          {
            value: this.getCountByName(this.headers[2], this.risks[0]),
            color: '#f0563e',
          },
          {
            value: this.getCountByName(this.headers[3], this.risks[0]),
            color: '#f0563e',
          },
        ],
      },
      {
        label: this.risks[1],
        cells: [
          {
            value: this.getCountByName(this.headers[0], this.risks[1]),
            color: '#6ac385',
          },
          {
            value: this.getCountByName(this.headers[1], this.risks[1]),
            color: '#fdd84b',
          },
          {
            value: this.getCountByName(this.headers[2], this.risks[1]),
            color: '#ff920f',
          },
          {
            value: this.getCountByName(this.headers[3], this.risks[1]),
            color: '#f0563e',
          },
        ],
      },
      {
        label: this.risks[2],
        cells: [
          {
            value: this.getCountByName(this.headers[0], this.risks[2]),
            color: '#6ac385',
          },
          {
            value: this.getCountByName(this.headers[1], this.risks[2]),
            color: '#fdd84b',
          },
          {
            value: this.getCountByName(this.headers[2], this.risks[2]),
            color: '#fdd84b',
          },
          {
            value: this.getCountByName(this.headers[3], this.risks[2]),
            color: '#ff920f',
          },
        ],
      },
      {
        label: this.risks[3],
        cells: [
          {
            value: this.getCountByName(this.headers[0], this.risks[3]),
            color: '#4eba6f',
          },
          {
            value: this.getCountByName(this.headers[1], this.risks[3]),
            color: '#6ac385',
          },
          {
            value: this.getCountByName(this.headers[2], this.risks[3]),
            color: '#fdd84b',
          },
          {
            value: this.getCountByName(this.headers[3], this.risks[3]),
            color: '#fdd84b',
          },
        ],
      },
      {
        label: this.risks[4],
        cells: [
          {
            value: this.getCountByName(this.headers[0], this.risks[4]),
            color: '#4eba6f',
          },
          {
            value: this.getCountByName(this.headers[1], this.risks[4]),
            color: '#4eba6f',
          },
          {
            value: this.getCountByName(this.headers[2], this.risks[4]),
            color: '#6ac385',
          },
          {
            value: this.getCountByName(this.headers[3], this.risks[4]),
            color: '#6ac385',
          },
        ],
      },
    ]
  }

  public redirectToAssetPage(cellIndex: number, rowIndex: number): void {
    const transformedRowIndex = 5 - rowIndex
    this.$router.push(
      `/asset/view?criticality=${cellIndex + 1}&risk=${transformedRowIndex}`
    )
  }
}
</script>
