<template>
  <div class="c-unitChild d-flex tw-flex-wrap tw-pl-5">
    <template v-if="units.length > 0">
      <UnitForm
        :company-id="companyId"
        v-for="child in units"
        :unit="child"
        :key="`unit_${parentId}_child_${child.id}`"
        @update="$emit('update')"
      />
    </template>
    <UnitForm
      :company-id="companyId"
      :parent-id="parentId"
      v-if="showEmptyForm"
      @deleteNew="$emit('deleteNew')"
      @update="$emit('update')"
    />
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import type { ICompanyUnit } from '@/types/CompaniesTypes'

@Component({
  emits: ['update'],
})
export default class UnitChild extends Vue {
  @Prop({ required: true })
  public companyId!: number

  @Prop({ required: true })
  public showEmptyForm!: boolean

  @Prop({ required: true })
  public parentId!: number

  @Prop({ required: true })
  public units!: ICompanyUnit[]
}
</script>
