import axios from 'axios'
import {
  setAuthorizationInterceptor,
  setTimeoutInterceptor,
} from '@/services/interceptors'

const API_URL: string = import.meta.env.VITE_API_URL
const MAX_WAIT_TIME = 100000

const client = axios.create({
  baseURL: API_URL + 'api/v1',
  timeout: MAX_WAIT_TIME,
  headers: { 'Content-Type': 'application/json' },
})

setAuthorizationInterceptor(client)
setTimeoutInterceptor(client, MAX_WAIT_TIME)

export default client
