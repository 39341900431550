<template>
  <div class="c-glossaryForm" v-if="form.length > 0">
    <div
      class="item"
      v-for="(item, index) in initialForm"
      :key="`formItem_${item.id}`"
      :ref="(el) => (itemRefs[index] = el)"
    >
      <template v-if="item.type === 'input'">
        <UIInput
          v-model="item.inputValue"
          @change="inputChange($event, item)"
          :label="item.name"
          :disabled="disabled"
          :is-tooltip="
            item.options.length > 0 && item.options[0].value.length > 0
          "
          :tooltip-text="getItemOptionValue(item.options[0] ?? null)"
          input-group-class="tw-mb-3"
          :labelOnBorder="true"
          :id="`formItem_${item.id}`"
          :name="`formItem_${item.id}`"
        />
        <p class="tw-text-xs tw-text-grey34 dark:tw-text-greyc9">
          {{ item.description }}
        </p>
      </template>
      <template v-else-if="item.type === 'select'">
        <UIListbox
          v-model="item.value"
          :label="item.name"
          :options="item.selectOptions"
          @change="selectChange($event, item)"
          :disabled="disabled"
          :placeholder="$t('views.assets.select')"
          class="tw-mb-3 tw-min-w-full tw-max-w-full"
        />
        <p class="tw-text-xs tw-text-grey34 dark:tw-text-greyc9">
          {{ item.description }}
        </p>
      </template>
      <template v-else-if="item.type === 'textArea'">
        <UITextarea
          v-model="item.inputValue"
          :label="item.name"
          :disabled="disabled"
          input-group-class="tw-mb-3"
          :is-tooltip="
            item.options.length > 0 && item.options[0].value.length > 0
          "
          :tooltip-text="getItemOptionValue(item.options[0] ?? null)"
        />
        <UIButton
          v-if="!disabled"
          :disabled="!item.inputValue"
          @click="textAreaChange(item.inputValue as string, item)"
          type="primary"
          class="tw-mt-2"
          :label="$t('button.saveChanges')"
          size="medium"
          button-class="tw-text-black"
        />
        <p class="tw-text-xs tw-text-grey34 dark:tw-text-greyc9">
          {{ item.description }}
        </p>
      </template>
      <template v-else-if="item.type === 'checkbox'">
        <UICheckbox
          v-model="item.value"
          @change="checkboxChange($event, item)"
          containerClass="tw-mb-3"
          :disabled="disabled"
          :is-tooltip="
            item.options.length > 0 && item.options[0].value.length > 0
          "
          :tooltip-text="getItemOptionValue(item.options[0] ?? null)"
        >
          {{ item.name }}
        </UICheckbox>
        <p class="tw-text-xs tw-text-grey34 dark:tw-text-greyc9">
          {{ item.description }}
        </p>
      </template>
      <template v-else-if="item.type === 'radio'">
        <p>{{ item.name }}</p>
        <p class="tw-text-xs tw-text-grey34 dark:tw-text-greyc9">
          {{ item.description }}
        </p>
        <UIRadio
          v-for="option in item.options"
          :id="`radio_${item.id}_${option.id}`"
          :key="`radio_${item.id}_${option.id}`"
          :name="`radio_${item.id}`"
          v-model="item.value"
          :value="option.id"
          :disabled="disabled"
          @change="radioChange($event, item)"
          class="tw-mb-3"
          :is-tooltip="option.description && option.description.length > 0"
          :tooltip-text="option.description"
        >
          {{ option.value }}
        </UIRadio>
      </template>

      <UIError
        class="tw-mb-4"
        :is-error="
          Boolean(validationErrors[item.id]) || isLastElementWithErrors(index)
        "
        :error="validationErrors[item.id] ?? validationErrors[0]"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import UIInput from '@/components/UI/UIInput.vue'
import { eventBus } from '@/helpers/EventBus'
import type { SelectOption } from '@/types/CommonTypes'
import UITextarea from '@/components/UI/UITextarea.vue'
import UICheckbox from '@/components/UI/UICheckbox.vue'
import UIRadio from '@/components/UI/UIRadio.vue'
import type { IFormItem, IItemOption } from '@/types/GlossaryFormTypes'
import UIButton from '@/components/UI/UIButton.vue'
import UIListbox from '@/components/UI/UIListbox.vue'
import type { Nullable } from '@/types/Nullable'
import { ref } from 'vue'
import UIError from '@/components/UI/UIError.vue'

@Component({
  components: {
    UIError,
    UIListbox,
    UIRadio,
    UICheckbox,
    UITextarea,
    UIInput,
    UIButton,
  },
  emits: ['saveItem', 'endForm', 'setLastElement'],
})
export default class Loader extends Vue {
  @Prop({ required: true })
  public initialForm!: IFormItem[]

  @Prop({ required: true, default: false })
  public disabled!: boolean

  @Prop({ required: false, default: {} })
  public validationErrors!: Record<string, string>

  public form: IFormItem[] = []

  public itemRefs = ref<Array<Nullable<HTMLElement>>>([])

  @Watch('initialForm')
  public onFormChange(): void {
    this.$emit('setLastElement', this.itemRefs[this.itemRefs.length - 1])
  }

  // eslint-disable-next-line
  public checkEndForm(item: IFormItem, value: any): void {
    if (this.isItemWithoutOptions(item)) {
      if (item.is_end) {
        this.$emit('endForm', true)
        eventBus.emit('endForm', true)
      }
      this.$emit('endForm', false)
      eventBus.emit('endForm', false)
      return
    }

    if (item.type === 'select') {
      const option = item.options.find((option) => option.id === value)
      this.$emit('endForm', Boolean(option && option.is_end))
      eventBus.emit('endForm', Boolean(option && option.is_end))
      return
    }

    const option = item.options.find((option) => option.id.toString() === value)
    this.$emit('endForm', Boolean(option && option.is_end))
    eventBus.emit('endForm', Boolean(option && option.is_end))
  }

  public isLastElementWithErrors(index: number): boolean {
    // 0 is the index of the error that affects newst field without id
    const hasLastError = this.validationErrors[0]
    if (!hasLastError) return false
    return index === this.initialForm.length - 1
  }

  public isItemWithoutOptions(item: IFormItem): boolean {
    return (
      item.type === 'input' ||
      item.type === 'textArea' ||
      item.type === 'checkbox'
    )
  }

  public inputChange(value: unknown, item: IFormItem): void {
    // eventBus.emit('glossaryInputChanged', { item, value })
    // eventBus.emit('saveItem', { item, value })
    this.$emit('saveItem', { item, value })
    this.checkEndForm(item, value)
  }

  public textAreaChange(inputValue: string, item: IFormItem): void {
    // eventBus.emit('glossaryTextAreaChanged', { item, value })
    // eventBus.emit('saveItem', { item, value })
    this.$emit('saveItem', { item, value: inputValue })
    this.checkEndForm(item, inputValue)
  }

  // eslint-disable-next-line
  public checkboxChange(checkbox: boolean, item: IFormItem): void {
    // eventBus.emit('glossaryCheckboxChanged', { item, value })
    // eventBus.emit('saveItem', { item, value })
    this.$emit('saveItem', { item, checkbox })
    this.checkEndForm(item, checkbox)
  }

  // eslint-disable-next-line
  public selectChange(value: any, item: IFormItem): void {
    if (typeof value === 'string' || typeof value === 'number') {
      this.$emit('saveItem', { item, value })
      this.checkEndForm(item, value)
    }
  }

  // eslint-disable-next-line
  public radioChange(radio: any, item: IFormItem): void {
    const value = radio.target.value
    this.$emit('saveItem', { item, value })
    this.checkEndForm(item, value)
  }

  public addNewFormElement(element: IFormItem): void {
    if (!this.form) return
    if (element.type === 'select') {
      element.selectOptions = this.getSelectOptions(element)
    }
    this.form.push(element)
  }

  public getSelectOptions(element: IFormItem): SelectOption[] {
    return element.options.map((item) => ({
      value: item.id,
      label: item.value,
    }))
  }

  public mounted(): void {
    this.form = this.initialForm
      .filter((el) => el !== null)
      .map((item) => {
        if (item.type === 'select') {
          item.selectOptions = this.getSelectOptions(item)
        }
        if (item.is_end) {
          eventBus.emit('endForm', true)
        }
        if (item.type === 'radio' || item.type === 'select') {
          item.options.forEach((option) => {
            if (option.is_end && item.value == option.id) {
              eventBus.emit('endForm', true)
            }
          })
        }
        return item
      })
    // @ts-ignore
    eventBus.on('addNewGlossaryItem', this.addNewFormElement)
  }

  public getItemOptionValue(itemOption: Nullable<IItemOption>): string {
    if (!itemOption) return ''
    return itemOption.value ?? ''
  }

  public destroyed(): void {
    // @ts-ignore
    eventBus.off('addNewGlossaryItem', this.addNewFormElement)
  }
}
</script>

<style lang="scss" scoped>
.description {
  font-size: 12px;
  color: #000;
}
</style>
