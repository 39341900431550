<template>
  <UIModal :showModal="isVisible" @close="handleClose">
    <template v-slot:header>
      <h6
        class="modal-title text-dark tw-font-bold"
        id="sessionExpiredModalLabel"
      >
        {{ $t('common.sessionTimeout.title') }}
      </h6>
      <UIButton button-class="btn-close" @click="handleClose">
        <font-awesome-icon :icon="['fas', 'xmark']" />
      </UIButton>
    </template>
    <template v-slot:default>
      <div class="small text-dark mt-2 tw-text-center">
        {{ $t('common.sessionTimeout.message') }}<br />
        {{ $t('common.sessionTimeout.stillTimeLeftSession') }}:
      </div>
      <div class="mt-3 text-center">
        <div class="time_expired_session progress-bar-session">
          {{ timer }}
        </div>
      </div>
      <div class="fs-7 text-dark mt-3 tw-text-center tw-font-bold">
        {{ $t('common.sessionTimeout.ExtendSession') }}
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex mt-4 tw-w-full tw-text-center">
        <UIButton
          button-class="tw-ml-auto !tw-w-auto"
          type="secondary"
          @click="handleLogout"
          :label="$t('common.sessionTimeout.buttons.logout')"
        >
        </UIButton>
        <UIButton
          button-class="ms-2 tw-mr-auto"
          type="primary"
          @click="extendSession"
          :label="$t('common.sessionTimeout.buttons.extendSession')"
        />
      </div>
    </template>
  </UIModal>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator'
import UIModal from '@/components/UI/UIModal.vue'
import UIButton from '@/components/UI/UIButton.vue'
import { expiredSessionModalService } from '@/main'

@Component({
  components: {
    UIModal,
    UIButton,
  },
  emits: ['logout', 'close', 'extendsession'],
})
export default class SessionTimeoutModal extends Vue {
  @Prop({ required: true, default: '' })
  public timer!: string

  public get isVisible(): boolean {
    return expiredSessionModalService.isOpen
  }

  public handleClose(): void {
    expiredSessionModalService.close()
  }

  public handleLogout(): void {
    this.$emit('logout')
  }

  public extendSession(): void {
    expiredSessionModalService.close()
    this.$emit('extendsession')
  }
}
</script>

<style scoped>
.time_expired_session {
  background: #f8d7da;
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.1em;
  text-align: center;
  display: inline-block;
  padding: 10px 15px;
  color: #dc3545;
}
.btn-close {
  background: none !important;
  color: #000 !important;
  border: 0 !important;
  box-shadow: none !important;
}
</style>
