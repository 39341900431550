import { Store, Pinia } from 'pinia-class-component'
import FilesRepository from '@/repositories/FilesRepository'
import type {
  IAttachementConfig,
  IAttachementTypes,
  IFilePreview,
  IMimeConfig,
} from '@/types/FileUploadTypes'
import type { Nullable } from '@/types/Nullable'
import filesRepository from '@/repositories/FilesRepository'
import { base64ToFile } from '@/helpers/base64ToFile'

@Store
export default class FileService extends Pinia {
  public attachementConfig: Nullable<IAttachementConfig> = null
  public mimeConfig: Nullable<IMimeConfig> = null

  // Mime config is to be fetched only once
  // as we receive config for all possible mime types in there
  public async getMimeConfig(): Promise<void> {
    if (this.mimeConfig) return
    await FilesRepository.getMimeTypes()
      .then((response) => {
        this.mimeConfig = response
      })
      .catch((e) => {
        console.error(e)
      })
  }

  // Attachment config is to be fetched only once
  // as we receive config for all possible attachment types in there
  public async getAttachementConfig(): Promise<void> {
    if (this.attachementConfig) return
    await FilesRepository.getAttachementConfig()
      .then((response) => {
        this.attachementConfig = response
      })
      .catch((e) => {
        console.error(e)
      })
  }

  public async getFiles(
    type: IAttachementTypes,
    id: number
  ): Promise<IFilePreview[]> {
    const filesList = await filesRepository.getFilesByTypeAndId(type, id)

    if (filesList?.length === 0) {
      return []
    }

    const filesArray = [] as number[]
    filesList.forEach((el) => filesArray.push(el.id))

    const files = await filesRepository.getFilesByIds(filesArray)

    return files.map((file): IFilePreview => {
      const fileItem = filesList.find((el) => el.id === file.fileId)
      return {
        id: file.fileId,
        attachment_id: fileItem?.attachment_id ?? -1,
        attachment_type: fileItem?.attachment_type ?? '',
        fileName: fileItem?.name ?? '',
        format: fileItem?.format ?? '',
        file: base64ToFile(
          file.file,
          fileItem?.name ?? '',
          fileItem?.format ?? ''
        ),
      }
    })
  }
}
