<template>
  <div class="c-dashboardBox tw-rounded-b-md tw-shadow-lg">
    <header
      class="tw-rounded-t-lg tw-bg-grey7b tw-py-1 tw-text-center tw-font-semibold tw-text-white"
    >
      {{ title }}
    </header>
    <Transition name="fade" mode="out-in">
      <div
        v-if="isLoading"
        class="tw-flex tw-justify-center tw-overflow-hidden tw-p-4"
      >
        <BaseLoader />
      </div>
      <div v-else>
        <slot />
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import type { TranslateResult } from 'vue-i18n'
import BaseLoader from '@/components/common/BaseLoader.vue'

@Component({
  components: {
    BaseLoader,
  },
})
export default class DashboardBox extends Vue {
  @Prop({ required: true })
  public title!: string | TranslateResult

  @Prop({ default: false })
  public isLoading!: boolean
}
</script>
