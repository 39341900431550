export default {
  tasks: {
    tasks: 'tasks',
    filters: 'Filters',
    introduction: 'Introduction',
    toBePerformed: 'To be performed',
    executed: 'Executed',
    fromUser: 'From user',
    toUser: 'To user',
    us: 'My',
    related: 'Related',
    notification: 'Notification',
    identification: 'Identification',
    assets: 'Assets',
    riskEstimation: 'Risk estimation',
    connections: 'Links',
    administrativeNotifications: 'Administrative notifications',
  },
}
