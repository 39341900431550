import { Store, Pinia } from 'pinia-class-component'
import type {
  IGlossaryAddForm,
  IGlossaryChildrenForEditRequestForm,
  IGlossaryChildrenRequestForm,
  IGlossaryFieldType,
  IGlossaryForm,
  IGlossaryFormType,
  IGlossaryHeader,
  IGlossaryHeaderForm,
  IGlossaryLineForm,
  IGlossaryLineTicket,
  IGlossaryTicketLineAddForm,
  IGlossaryTicketLineUpdateForm,
} from '@/types/GlossariesTypes'
import GlossariesRepository from '@/repositories/GlossariesRepository'
import type { Nullable } from '@/types/Nullable'

interface IGlossaryStore {
  glossaryFormTypes: IGlossaryFormType[]
  forms: IGlossaryForm[]
  form: Nullable<IGlossaryForm>
  mainHeaders: IGlossaryHeader[]
  childrenHeaders: IGlossaryHeader[]
  isLoading: boolean
  isError: boolean
  fieldTypes: IGlossaryFieldType[]
  ticketLines: IGlossaryLineTicket[]
}

@Store
export default class GlossaryService extends Pinia {
  public store: IGlossaryStore = {
    glossaryFormTypes: [],
    forms: [],
    form: null,
    mainHeaders: [],
    childrenHeaders: [],
    isLoading: false,
    isError: false,
    fieldTypes: [],
    ticketLines: [],
  }

  public get glossaryFormTypes(): IGlossaryFormType[] {
    return this.store.glossaryFormTypes
  }

  public get forms(): IGlossaryForm[] {
    return this.store.forms
  }

  public get mainHeaders(): IGlossaryHeader[] {
    return this.store.mainHeaders
  }

  public get fieldTypes(): IGlossaryFieldType[] {
    return this.store.fieldTypes
  }

  public get childrenHeaders(): IGlossaryHeader[] {
    return this.store.childrenHeaders
  }

  public get ticketLines(): IGlossaryLineTicket[] {
    return this.store.ticketLines
  }

  public get form(): Nullable<IGlossaryForm> {
    return this.store.form
  }

  public get isLoading(): boolean {
    return this.store.isLoading
  }

  public get isError(): boolean {
    return this.store.isError
  }

  public async loadGlossaryFormTypes(): Promise<void> {
    this.store.isLoading = true
    await GlossariesRepository.getFormTypes()
      .then((response) => {
        this.store.glossaryFormTypes = response
      })
      .catch(() => {
        this.store.glossaryFormTypes = []
        this.store.isError = true
        throw new Error('Error while loading glossary form types')
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadGlossaryFieldTypes(): Promise<void> {
    this.store.isLoading = true
    await GlossariesRepository.getFieldTypes()
      .then((response) => {
        this.store.fieldTypes = response
      })
      .catch(() => {
        this.store.fieldTypes = []
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadGlossaryForms(): Promise<void> {
    this.store.isLoading = true
    await GlossariesRepository.getForms()
      .then((response) => {
        this.store.forms = response
      })
      .catch(() => {
        this.store.forms = []
        this.store.isError = true
        throw new Error('Error while loading glossary forms')
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadGlossaryForm(id: number): Promise<void> {
    this.store.isLoading = true
    await GlossariesRepository.getForm(id)
      .then((response) => {
        this.store.form = response
      })
      .catch(() => {
        this.store.form = null
        this.store.isError = true
        throw new Error('Error while loading glossary form')
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async addGlossaryForm(form: IGlossaryAddForm): Promise<void> {
    this.store.isLoading = true
    await GlossariesRepository.addForm(form)
      .then((response) => {
        this.store.form = response
      })
      .catch((error) => {
        this.store.form = null
        this.store.isError = true
        throw error
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadGlossaryForTicket(
    idTicket: number
  ): Promise<IGlossaryLineTicket[] | void> {
    this.store.isLoading = true
    this.store.ticketLines = []
    return await GlossariesRepository.getGlossaryLinesForTicket(idTicket)
      .then((response) => {
        return [...response]
      })
      .catch(() => {
        this.store.ticketLines = []
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async updateGlossaryForm(form: IGlossaryForm): Promise<void> {
    this.store.isLoading = true
    await GlossariesRepository.updateForm(form)
      .then((response) => {
        this.store.form = response
      })
      .catch(() => {
        this.store.form = null
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async activateGlossaryForm(form: IGlossaryForm): Promise<void> {
    this.store.isLoading = true
    await GlossariesRepository.activateForm(form)
      .then((response) => {
        this.store.form = response
      })
      .catch(() => {
        this.store.form = null
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadGlossaryFormMainHeaders(id: number): Promise<void> {
    this.store.isLoading = true
    await GlossariesRepository.getGlossaryHeadersForForm(id)
      .then((response) => {
        this.store.mainHeaders = response
      })
      .catch((e) => {
        this.store.mainHeaders = []
        this.store.isError = true
        throw e
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async updateGlossaryHeader(form: IGlossaryHeaderForm): Promise<void> {
    this.store.isLoading = true
    await GlossariesRepository.updateGlossaryHeader(form)
      .then(() => {})
      .catch(() => {
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async updateGlossaryLine(form: IGlossaryLineForm): Promise<void> {
    this.store.isLoading = true
    await GlossariesRepository.updateGlossaryLine(form)
      .then(() => {})
      .catch(() => {
        this.store.isError = true
        throw new Error('Error while update glossary line')
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async addGlossaryLine(form: IGlossaryLineForm): Promise<void> {
    this.store.isLoading = true
    await GlossariesRepository.addGlossaryLine(form)
      .then(() => {})
      .catch(() => {
        this.store.isError = true
        throw new Error('Error while add new glossary line')
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async addGlossaryHeader(
    form: IGlossaryHeaderForm
  ): Promise<Nullable<number>> {
    this.store.isLoading = true
    return await GlossariesRepository.addGlossaryHeader(form)
      .then((response) => {
        return response
      })
      .catch(() => {
        this.store.isError = true
        return null
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async deleteGlossaryLine(id: number): Promise<void> {
    this.store.isLoading = true
    await GlossariesRepository.deleteGlossaryLine(id)
      .then(() => {})
      .catch(() => {
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadGlossaryFormChildrenHeaders(
    form: IGlossaryChildrenRequestForm
  ): Promise<void> {
    this.store.isLoading = true
    this.store.childrenHeaders = []
    await GlossariesRepository.getGlossaryChildrenHeaders(form)
      .then((response) => {
        this.store.childrenHeaders = response
      })
      .catch(() => {
        this.store.childrenHeaders = []
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadGlossaryFormChildrenHeadersForEdit(
    form: IGlossaryChildrenForEditRequestForm
  ): Promise<void> {
    this.store.isLoading = true
    this.store.childrenHeaders = []
    await GlossariesRepository.getGlossaryChildrenHeadersForEdit(form)
      .then((response) => {
        this.store.childrenHeaders = response
      })
      .catch(() => {
        this.store.childrenHeaders = []
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async updateGlossaryLineTicket(
    ticket_id: number,
    form: IGlossaryTicketLineUpdateForm
  ): Promise<void> {
    this.store.isLoading = true
    await GlossariesRepository.updateTicketLine(ticket_id, form)
      .catch((error) => {
        this.store.isError = true
        throw error
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async addGlossaryLineTicket(
    ticket_id: number,
    form: IGlossaryTicketLineAddForm
  ): Promise<void> {
    this.store.isLoading = true
    await GlossariesRepository.addTicketLine(ticket_id, form)
      .then(() => {})
      .catch((error) => {
        this.store.isError = true
        throw error
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }
}
