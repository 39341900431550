<template>
  <button
    :title="title"
    :class="[
      {
        'btn bg-gradient-primary !tw-text-sm !tw-font-semibold !tw-text-white':
          type === 'primary',
      },
      {
        'btn dark:btn-outline-dark btn-icon btn-3 tw-border !tw-border-greyd2 !tw-text-sm !tw-font-semibold hover:tw-opacity-75 focus:!tw-shadow-none ':
          type === 'secondary',
      },
      {
        'tw-rounded-full !tw-text-xs tw-font-light tw-uppercase hover:tw-opacity-80':
          type === 'tertiary',
      },
      {
        'tw-mr-1 tw-mt-1 tw-rounded-full !tw-border tw-px-3 tw-py-0.5 tw-text-xs tw-font-light tw-uppercase hover:tw-opacity-80':
          type === 'pill',
      },
      {
        '!dark:tw-text-redf4 !tw-border-redf4 !tw-text-redf4': color === 'red',
      },
      {
        '!dark:tw-text-green19 !tw-border-green19 !tw-text-green19':
          color === 'green',
      },
      {
        '!dark:tw-text-orange-400 !tw-border-orange-500 !tw-text-orange-400':
          color === 'yellow',
      },
      { 'tw-py-3': size === 'big' },
      { 'tw-py-2': size === 'medium' },
      { 'tw-px-3 tw-py-0.5': size === 'small' },
      { 'tw-cursor-not-allowed tw-opacity-50': isDisabled },
      'c-uiButton tw-text-center dark:tw-text-greye9',
      buttonClass,
    ]"
    :type="htmlButtonType"
    :disabled="isDisabled"
    v-bind="$attrs"
  >
    <template v-if="!isLoading">
      <slot name="left" />
      {{ label }}
      <slot name="right" />
    </template>
    <template v-else>
      <div class="tw-flex tw-justify-center">
        <BaseLoader size="small" />
      </div>
    </template>
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import type { TranslateResult } from 'vue-i18n'
import BaseLoader from '@/components/common/BaseLoader.vue'

export type IUIButtonTypes = 'primary' | 'secondary' | 'tertiary' | 'pill' | ''
export type IUIButtonColors = 'red' | 'green' | 'yellow'
export type IUIButtonSizes = 'big' | 'medium' | 'small'
export type IUIButtonHTMLTypes = 'button' | 'submit' | 'reset'

@Component({
  components: {
    BaseLoader,
  },
})
export default class UIButton extends Vue {
  @Prop({ required: false, default: '' })
  public type!: IUIButtonTypes

  @Prop({ required: false, default: '' })
  public color!: IUIButtonColors

  @Prop({ required: false, default: 'button' })
  public htmlButtonType!: IUIButtonHTMLTypes

  @Prop({ required: false })
  public title!: string

  @Prop({ required: false, default: '' })
  public size!: IUIButtonSizes

  @Prop({ required: false })
  public buttonClass!: string

  @Prop()
  public label!: string | TranslateResult

  @Prop({ required: false, default: false })
  public isLoading!: boolean

  @Prop({ required: false, default: false })
  public disabled!: boolean

  public get isDisabled(): boolean {
    return this.disabled || this.isLoading
  }
}
</script>
