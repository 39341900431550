<template>
  <UIModal
    :showModal="isVisible"
    @close="$emit('close')"
    class="c-confirmActionModal"
  >
    <template #header>
      <h3 class="tw-mt-5 tw-w-full tw-text-center">
        <template v-if="!labelIsTitle">
          {{ title ?? $t('common.modals.confirmAction') }}
        </template>
        <template v-else>
          {{ label }}
        </template>
      </h3>
    </template>
    <template #default>
      <div class="tw-text-center" v-if="!labelIsTitle">
        {{ label }}
      </div>
    </template>
    <template v-slot:footer>
      <UIButton
        type="secondary"
        class="!tw-max-w-max"
        :label="$t('common.modals.cancel')"
        size="medium"
        @click="$emit('close')"
        button-class="tw-text-black"
      />

      <UIButton
        type="primary"
        :label="$t('common.modals.confirm')"
        size="medium"
        button-class="tw-text-black"
        @click="callFunction"
      />
    </template>
  </UIModal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import UIModal from '@/components/UI/UIModal.vue'
import UIButton from '@/components/UI/UIButton.vue'
import type { Nullable } from '@/types/Nullable'

@Component({
  components: { UIButton, UIModal },
})
export default class ConfirmActionModal extends Vue {
  @Prop({ required: true }) isVisible: boolean = false
  @Prop({ required: false, default: false }) labelIsTitle: boolean = false
  @Prop({ required: true }) functionCall: Nullable<() => void> = null
  @Prop({ required: true }) label: string = ''
  @Prop({ required: true }) title: string = ''

  public callFunction(): void {
    if (this.functionCall) {
      this.functionCall()
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss"></style>
