export default {
  consents: {
    consents: 'Zgody i informacje',
    newConsents: 'Dodanie nowej zgody/informacji',
    consentType: 'Rodzaj',
    editConsents: {
      1: 'Edytuj zgody',
      2: 'Edytuj informacje',
    },
    descriptionPreview: 'Podgląd treści',
    description: {
      1: 'Proszę wprowadzić treść zgody',
      2: 'Proszę wprowadzić treść informacji',
    },
    activeConsent: {
      1: 'Proszę wybrać status zgody',
      2: 'Proszę wybrać status informacji',
    },
    name: 'Nazwa systemowa',
    header: 'Wyświetlany nagłówek',
    yes: 'aktywny',
    no: 'nieaktywny',
    companies: {
      1: 'Proszę wybrać podmiot dla którego dana zgoda ma być aktywna',
      2: 'Proszę wybrać podmiot dla którego dana informacja ma być aktywna',
    },
    selectAllCompanies: {
      1: 'Zaznacz wszystkie podmioty dla danej zgody',
      2: 'Zaznacz wszystkie podmioty dla danej informacji',
    },
    previewConsents: {
      1: 'Podgląd zgody',
      2: 'Podgląd informacji',
    },
    username: 'Użytkownik',
    acceptDate: 'Data akceptacji',
    acceptedConsents: 'Użytkownicy którzy zaakceptowali zgodę/informację',
    successUpdate: 'Zgoda została zaktualizowana',
    errorUpdate: 'Błąd podczas aktualizacji zgody/informacji',
    createSuccess: 'Zgoda została stworzona',
    activateConsent: 'Aktywuj zgodę',
    activeAttribute: 'Czy atrybut jest aktywny?',
    deactivateConsent: 'Dezaktywuj zgodę',
    editConsent: 'Edytuj zgodę',
    showConsent: 'Pokaż zgodę',
    filesAttached: 'Pliki załączone do zgody',
    infoMessage: `Ta funkcjonalność służy do wprowadzania i zarządzania zgodami lub
      informacjami, które użytkownicy systemu SYMBIONIS muszą wyrazić bądź z
      którymi mają obowiązek się zapoznać. Administratorzy systemu mają
      możliwość dodawania nowych zgód lub informacji oraz zarządzania ich
      aktywnością. Może to być zgoda, którą użytkownik musi wyrazić, na przykład
      na przetwarzanie danych osobowych, lub informacja, z którą musi się
      zapoznać, jak aktualizacja procedur bezpieczeństwa. Dodatkowo,
      administratorzy mogą określić, dla której firmy lub jednostki dana zgoda
      lub informacja ma być aktywna, a także decydować o jej aktywności, czyli o
      tym, czy będzie widoczna dla użytkowników w systemie.
      <br /><br />
      Każda zgoda lub informacja posiada swoją historię zmian oraz listę
      akceptacji, co umożliwia pełną rozliczalność i weryfikację spełnienia
      obowiązku informacyjnego lub uzyskania odpowiednich zgód. Historia zmian
      pozwala śledzić modyfikacje wprowadzane do treści zgody lub informacji, a
      lista akceptacji umożliwia administratorom sprawdzenie, które osoby
      zapoznały się z treścią lub wyraziły zgodę.
      <br /><br />
      Po aktywacji zgody lub informacji użytkownicy będą zobowiązani do jej
      zaakceptowania lub zapoznania się z jej treścią przy pierwszym logowaniu
      do systemu albo w trakcie procesu obsługi zgłoszeń. Ważne jest, aby
      administratorzy upewnili się, że treść zgód i informacji jest jasna,
      zrozumiała, a także zgodna z obowiązującymi przepisami i politykami
      wewnętrznymi firmy.`,
  },
}
