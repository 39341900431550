import type { Nullable } from '@/types/Nullable'
import type { IUserRelations } from '@/types/UserTypes'
import type { ObjectValues } from '@/types/HelperTypes'
import type { IAnonymousData } from '@/types/AnonymousTicketTypes'
import type { IFileListItem } from '@/types/FileUploadTypes'
import type { IRole } from '@/types/RolesTypes'
import type { ICompanyUnit } from '@/types/CompaniesTypes'

export const ETICKET_STATUSES = {
  NEW: 1,
  OPEN: 2,
  ASSIGNED: 3,
  IN_VERIFICATION: 4,
  VERIFIED: 5,
  PROCESSED: 6,
  EXPLAINED: 7,
  FINISHED: 8,
} as const

export const EIDENTIFICATION_ACTIONS = {
  BEGIN: 'BEGIN',
  FINISH: 'FINISH',
  REVERT: 'REVERT',
  TRANSFER_TO_EXPERT: 'TRANSFER_TO_EXPERT',
  RETURNED_FROM_EXPERT: 'RETURNED_FROM_EXPERT',
  TRANSFER_TO_COORDINATOR: 'TRANSFER_TO_COORDINATOR',
} as const

export const EUPDATE_IDENTIFICATION_STATUS_ACTIONS = {
  BEGIN: 'BEGIN',
  CONFIRM: 'CONFIRM',
  FINISH: 'FINISH',
} as const

export const EACCESS = {
  DENIED: -1,
  NEUTRAL: 0,
  ALLOWED: 1,
} as const

export type ITicketStatus = ObjectValues<typeof ETICKET_STATUSES>
export type IdentificationAction = ObjectValues<typeof EIDENTIFICATION_ACTIONS>
export type UpdateIdentificationStatusActions = ObjectValues<
  typeof EUPDATE_IDENTIFICATION_STATUS_ACTIONS
>
export type Access = ObjectValues<typeof EACCESS>

export interface ITicket {
  id: number
  formatted_id: string
  formatted_index: Nullable<number>
  extension_id: Nullable<number>
  status_id: ITicketStatus
  source_id: number
  anonymous_id: Nullable<number>
  type_id: number
  asset_id: Nullable<number>
  related_ticket_id: Nullable<number>
  created_at: string
  company_id: Nullable<number>
  company_unit_id: Nullable<number>
  workflow_id: Nullable<number>
}

export interface ITicketItem extends ITicket {
  status: IIdentificationStatus
  anonymous?: Nullable<IAnonymousData>
  user?: IUserRelations
  identification?: IIdentification
  logs: ITicketLogs[]
  identificationSummary?: ITicketIdentificationSummary
}

export interface ITicketIdentificationSummary {
  eventDescription: string
  breachDescription: string
  eventClassification: string
  dataSubjectsRightsRiskLevel: string
  financialLoss: string
  notificationStructure: string
}

export interface IIdentificationResponse extends ITicket {
  user: Nullable<IUserRelations>
  ticket: ITicket
  status: IIdentificationStatus
  categories: ITicketIdentificationCategories[]
}

export type UserOption = {
  name: string
  id: number
  roles: string
  hasAccess: Access
}

export interface ITicketUpdatePayload {
  companyId?: number
  unitId?: number
  status?: number
  assetId?: number
}

export interface IAccess {
  id: number
  ticket_id: number
  hash: string
  pin: number
}
export interface ICreatedTicket {
  user_id: number
  type_id: number
  updated_at: string
  created_at: string
  id: number
}

export interface Status {
  id: number
  name: string
  synchronized: number
}
export interface IIdentificationStatus {
  id: number
  name: string
  synchronized: number
}

export interface IIdentification {
  id: number
  formatted_id: Nullable<number>
  formatted_index: Nullable<number>
  extension_id: Nullable<number>
  synchronized: number
  status_id: ITicketStatus
  source_id: Nullable<number>
  type_id: number
  asset_id: Nullable<number>
  related_ticket_id: number
  created_at: string
  company_id: Nullable<number>
  company_unit_id: Nullable<number>
  workflow_id: number
  identification_additional_data: Nullable<unknown>
}

export interface ICreateIdentificationCategoryResponse {
  identification_category_id: number
  categories_number: number
  ticket_id: number
  user_id: number
  description: string
  is_summary: 0 | 1
  updated_at: string
  created_at: string
  id: number
}
export interface ITicketIdentificationCategories {
  id: number
  ticket_id: number
  coordinator_id: number
  expert_id: Nullable<number>
  categories_id: Nullable<number>
  categories_number: Nullable<number>
  status_id: ITicketStatus
  created_at: string
  updated_at: string
  deleted_at: Nullable<string>
  workflow_id: number
  is_summary: 0 | 1
  processes: IProcess[]
  categories_by_id?: Nullable<ICategoriesResponse>
}

export interface IProcess {
  id: number
  identification_category_id: number
  user_id: number
  is_summary: 0 | 1
  description: string
  created_at: string
  updated_at: string
  users: IUserRelations
  permissions: IPermission[]
  files?: IFileListItem[]
}

export interface ICreateIdentificationCategoryPayload {
  identificationCategoryId: number
  userId: number
  description: string
  permissions: Permission[]
}

export type IdentificationCategoryPayload = {
  description: string
  identificationCategoryId: number
  userId: number
  permissions: Permission[]
}

export interface IPermission {
  id: number
  process_id: number
  user_id: number
  access: Access
  created_at: string
  updated_at: string
  users: IUserRelations
}

export interface Permission {
  userId: number
  access: number
}
export interface ICategoriesResponse {
  id: number
  name: string
  title: Nullable<string>
  description: Nullable<string>
  category_by_id_exists: boolean
  active: number
  number: number
  created_at: Nullable<string>
  updated_at: Nullable<string>
  deleted_at: Nullable<string>
  users: IUserRelations[]
}
export interface IAssignCategoryPayload {
  userId: number
  category_number: number
  identificationId: number
}

export interface IIdentificationCategoryUpdatePayload {
  action: IdentificationAction
  userId?: number
  description?: string
}

export interface IUpdateIdentificationPayload {
  action: UpdateIdentificationStatusActions
  description?: string
}

export interface IIdentificationCategoryList {
  id: number
  number: number
  name: Nullable<string>
  title: Nullable<string>
  description: Nullable<string>
  active: 0 | 1
  created_at: string
  updated_at: string
  deleted_at: string
}

export interface ITicketLogs {
  id: number
  ticket_id: number
  user_id: number
  message_key: string
  message: string
  payload: string
  created_at: string
  updated_at: string
}

export interface ITicketSource {
  id: number
  number: number
  prefix: string
  name: string
  description: string
  active: number
  hide: number
  created_at: string
  updated_at: string
}
export interface ISourcesForm {
  prefix: string
  name: string
  description: string
  hide: 0 | 1
}

export interface ISourcesFormErrors {
  prefix: string[]
  name: string[]
  description: string[]
}

export interface IStatusOptions {
  value: string | number
  label: string
}

// TODO: Verify w/ Mateusz if we really need ticket relation in here
export interface ITicketMessages {
  ticket: ITicketItem
  message: IMessage[]
}

export interface IMessage {
  id: number
  ticket_id: number
  user_id: Nullable<number>
  message: string
  created_at: string
  updated_at: string
  user?: IUserRelations
}

export interface IStatuses {
  mainProcesses: IStatus[]
  identification?: IStatus
  categories?: IStatus[]
}

interface IStatus {
  userFullName: string
  status: string
  processName: string
}

export interface ITickerSharePayload {
  userId: number
  unitId: number
  roleId: number
  expiresAt: string
}

export interface ITicketShare {
  id: number
  ticket_id: number
  user_id: number
  unit_id: number
  role_id: number
  expires_at: string
  created_at: string
  updated_at: string
  deleted_at: Nullable<string>
  user: IUserRelations
  unit: Omit<ICompanyUnit, 'users' | 'children'>
  role: Omit<IRole, 'users'>
}
