<template>
  <div
    :class="[
      'c-uiChartBar relative tw-w-full tw-overflow-auto',
      containerClass,
    ]"
  >
    <div class="tw-w-full">
      <V-Bar
        :data="data"
        :options="options"
        class="tw-min-h-[400px] tw-min-w-full"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import type { ChartOptions } from 'chart.js'

interface IData {
  labels: string[]
  datasets: { data: number[]; backgroundColor: string[] }[]
}

@Component({})
export default class UIChartBar extends Vue {
  @Prop({ required: false })
  public containerClass: string = ''

  @Prop({ required: false })
  public customOptions: ChartOptions<'bar'> = {}

  @Prop({ required: true })
  public data: IData = {
    labels: [],
    datasets: [],
  }

  public get options(): ChartOptions<'bar'> {
    return (
      this.customOptions || {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      }
    )
  }
}
</script>
