<template>
  <div class="c-baselineThreats">
    <DashboardBox
      :title="$t('views.dashboard.baselineThreats')"
      :is-loading="isLoading"
    >
      <slot>
        <UIGridTable
          :headers="baselineThreatsHeaders"
          :gridTemplateColumns="gridTemplateColumns"
        >
          <template v-slot:default>
            <div
              class="grid-template-columns tw-grid tw-grid-cols-3 tw-text-sm xl:tw-text-base"
              :style="{ 'grid-template-columns': gridTemplateColumns }"
              v-for="(threat, index) in baselineThreats"
              :key="`baselineThreat_${index}`"
            >
              <div class="tw-p-2">{{ threat.name }}</div>
              <div
                :class="[
                  { 'tw-bg-red': threat.weight > 2 },
                  { 'tw-bg-greend4': threat.weight < 2 },
                  { 'tw-bg-yellowff': threat.weight === 2 },
                  'tw-p-2 dark:tw-text-black',
                ]"
              >
                {{ riskLevel(threat.weight) }}
              </div>
              <div class="tw-m-auto tw-p-2">
                <span
                  :class="[
                    {
                      'tw-bg-yellowff': riskTrend(threat) === 0,
                    },
                    {
                      'tw-bg-greend4': riskTrend(threat) === -1,
                    },
                    {
                      'tw-bg-red': riskTrend(threat) === 1,
                    },
                    'tw-block tw-h-4 tw-w-4',
                  ]"
                ></span>
              </div>
            </div>
          </template>
        </UIGridTable>
      </slot>
    </DashboardBox>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator'
import DashboardBox from '@/components/views/dashboard/DashboardBox.vue'
import UIGridTable from '@/components/UI/UIGridTable.vue'
import type { IWidgetBaselineThreats } from '@/types/WidgetTypes'

@Component({
  components: { UIGridTable, DashboardBox },
})
export default class BaselineThreats extends Vue {
  @Prop({ default: false })
  public isLoading!: boolean

  @Prop({ default: false })
  public baselineThreats!: IWidgetBaselineThreats[]

  public gridTemplateColumns: string = 'auto 70px 70px'

  public get baselineThreatsHeaders(): string[] {
    return [
      this.$t('views.dashboard.threat'),
      this.$t('views.dashboard.level'),
      this.$t('views.dashboard.trend'),
    ]
  }

  public riskTrend(threat: IWidgetBaselineThreats): number {
    const { tickets_last_7_days_count, tickets_8_to_14_days_ago_count } = threat
    if (tickets_last_7_days_count > tickets_8_to_14_days_ago_count) return 1
    if (
      tickets_last_7_days_count < tickets_8_to_14_days_ago_count ||
      (tickets_last_7_days_count === 0 && tickets_8_to_14_days_ago_count === 0)
    )
      return -1
    return 0
  }

  public riskLevel(weight: number): string {
    if (weight > 2) return 'Istotny'
    if (weight === 2) return 'Średni'
    return 'Niski'
  }
}
</script>
