import { errorsService } from '@/main'
import client from '@/services/client'
import { errorsEnum } from '@/types/ErrorTypes'
import { isAxiosError } from 'axios'
import type {
  IGlossaryAddForm,
  IGlossaryChildrenForEditRequestForm,
  IGlossaryChildrenRequestForm,
  IGlossaryFieldType,
  IGlossaryForm,
  IGlossaryFormType,
  IGlossaryHeader,
  IGlossaryHeaderForm,
  IGlossaryLineForm,
  IGlossaryLineTicket,
  IGlossaryTicketLineAddForm,
  IGlossaryTicketLineUpdateForm,
} from '@/types/GlossariesTypes'
import type { Nullable } from '@/types/Nullable'

class GlossariesRepository {
  public async getFormTypes(): Promise<IGlossaryFormType[]> {
    return await client
      .get<IGlossaryFormType[]>('glossaries/types')
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getFieldTypes(): Promise<IGlossaryFieldType[]> {
    return await client
      .get<IGlossaryFieldType[]>('glossaries/fields/types')
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getGlossaryLinesForTicket(
    id: number
  ): Promise<IGlossaryLineTicket[]> {
    return await client
      .get(`glossaries/ticket/${id}/lines`)
      .then((re) => {
        if (re.data.length === 1) {
          return [null, re.data[0]]
        }
        return [null, ...re.data] ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getForms(): Promise<IGlossaryForm[]> {
    return await client
      .get<IGlossaryForm[]>('glossaries')
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getForm(id: number): Promise<IGlossaryForm> {
    return await client
      .get<IGlossaryForm>(`glossaries/${id}`)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async updateGlossaryHeader(
    form: IGlossaryHeaderForm
  ): Promise<IGlossaryForm> {
    return await client
      .put<IGlossaryForm>(`glossaries/headers/${form.id}`, form)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async addGlossaryLine(
    form: IGlossaryLineForm
  ): Promise<IGlossaryForm> {
    return await client
      .post<IGlossaryForm>(`glossaries/lines`, form)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async updateGlossaryLine(
    form: IGlossaryLineForm
  ): Promise<IGlossaryForm> {
    return await client
      .put<IGlossaryForm>(`glossaries/lines/${form.id}`, form)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async addGlossaryHeader(form: IGlossaryHeaderForm): Promise<number> {
    return await client
      .post<IGlossaryHeader>(`glossaries/${form.form_id}/headers`, form)
      .then((response) => {
        return response.data.id ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async deleteGlossaryLine(id: number): Promise<number> {
    return await client
      .delete<number>(`glossaries/lines/${id}`)
      .then((response) => {
        return response.data ?? 0
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async addForm(
    form: IGlossaryAddForm
  ): Promise<Nullable<IGlossaryForm>> {
    return await client
      .post<IGlossaryForm>('glossaries', {
        id: null,
        name: form.name,
        type_id: Number(form.type_id),
        active: form.active ? 1 : 0,
      })
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async updateForm(
    form: IGlossaryForm
  ): Promise<Nullable<IGlossaryForm>> {
    return await client
      .put<IGlossaryForm>(`glossaries/${form.id}`, {
        id: form.id,
        name: form.name,
        type_id: Number(form.type_id),
        active: form.active ? 1 : 0,
      })
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async activateForm(
    form: IGlossaryForm
  ): Promise<Nullable<IGlossaryForm>> {
    return await client
      .post<IGlossaryForm>(`glossaries/${form.id}/activate`, {
        type_id: Number(form.type_id),
      })
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getGlossaryHeadersForForm(
    id: number
  ): Promise<IGlossaryHeader[]> {
    return await client
      .get<IGlossaryHeader[]>(`glossaries/${id}/headers`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getGlossaryChildrenHeaders(
    form: IGlossaryChildrenRequestForm
  ): Promise<IGlossaryHeader[]> {
    return await client
      .get<IGlossaryHeader>(
        `glossaries/types/${form.formId}/headers/${form.parentHeaderId}`
      )
      .then((response) => {
        return [response.data] ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getGlossaryChildrenHeadersForEdit(
    form: IGlossaryChildrenForEditRequestForm
  ): Promise<IGlossaryHeader[]> {
    return await client
      .get<IGlossaryHeader>(
        `glossaries/${form.formTypeId}/headers/${form.parentHeaderId}`
      )
      .then((response) => {
        return [response.data] ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async updateTicketLine(
    ticket_id: number,
    form: IGlossaryTicketLineUpdateForm
  ): Promise<Nullable<IGlossaryForm>> {
    return await client
      .put<IGlossaryForm>(`glossaries/ticket/${ticket_id}/lines`, form)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async addTicketLine(
    ticket_id: number,
    form: IGlossaryTicketLineAddForm
  ): Promise<Nullable<IGlossaryForm>> {
    return await client
      .post<IGlossaryForm>(`glossaries/ticket/${ticket_id}/lines`, {
        lines: [form],
      })
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetGlossaries,
            response: error.response,
          })
        }

        throw error
      })
  }
}

export default new GlossariesRepository()
