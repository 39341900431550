import { Store, Pinia } from 'pinia-class-component'
import type {
  IAuthAuth,
  ILoginForm,
  IRegisterForm,
  IUserRelations,
  IUserCategories,
  IMenuItem,
  IUserAuth,
} from '@/types/UserTypes'
import UserRepository from '@/repositories/UserRepository'
import { getTokenCookie } from '@/helpers/getTokenCookie'
import { AxiosError } from 'axios'
import type { Nullable } from '@/types/Nullable'
import AuthRepository, { type IOptions } from '@/repositories/AuthRepository'
import CaptchaRepository from '@/repositories/CaptchaRepository'
import { LocalStorageKeys } from '@/types/LocalStorageKeys'
import type { IContextGetResponse } from '@/repositories/UsersManagementRepository'
import { environmentsManager } from '@/main'
import type { IUserPermissionModule } from '@/types/PermissionsTypes'
import type { IPermArray } from '@/types/PermissionsModules'
import { getDomain } from '@/helpers/getDomain'

interface IUserStore {
  isLoading: boolean
  isError: boolean
  isAuthenticatorEnabled: boolean
  isAuthRegenApproved: boolean
  token: string
  highlightRole: boolean
  users: IUserRelations[]
  user: Nullable<IUserRelations>
  categories: IUserCategories[]
  menuItems: Nullable<IMenuItem>
  unreadNotifications: number
  context: Nullable<IContextGetResponse>
  permissions: IUserPermissionModule[]
  usersDatatableDropdown: Nullable<number>
  reauthPending: boolean
}

@Store
export default class UserService extends Pinia {
  public store: IUserStore = {
    isLoading: false,
    isError: false,
    isAuthenticatorEnabled: false,
    isAuthRegenApproved: true,
    token: '',
    highlightRole: false,
    users: [],
    user: null,
    categories: [],
    menuItems: null,
    unreadNotifications: 0,
    context: null,
    permissions: [],
    usersDatatableDropdown: null,
    reauthPending: false,
  }

  public get isLoggedIn(): boolean {
    return Boolean(this.store.user)
  }
  public get isLoading(): boolean {
    return this.store.isLoading
  }

  public get isError(): boolean {
    return this.store.isError
  }

  public get hasAuthenticator(): boolean {
    return this.store.isAuthenticatorEnabled
  }

  public get users(): IUserRelations[] {
    return this.store.users
  }

  public get getUser(): Nullable<IUserRelations> {
    return this.store.user
  }

  public get getHighlight(): boolean {
    return this.store.highlightRole
  }

  public get menuItems(): IMenuItem {
    return this.store.menuItems ?? {}
  }

  public get isReauthPending(): boolean {
    return this.store.reauthPending
  }

  public get permissions(): IUserPermissionModule[] {
    return this.store.permissions
  }

  public getTokenCookie(): string {
    return getTokenCookie(document.cookie)
  }

  public getToken(): string {
    const token = this.getTokenCookie()
    if (token) return token
    return this.store.token ? this.store.token : ''
  }

  public clearUserData(): void {
    const domain = getDomain()
    document.cookie.split(';').forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(
          /=.*/,
          '=;expires=' + new Date().toUTCString() + `;path=/; domain=.${domain}`
        )
    })
    this.store.token = ''
    this.store.isAuthenticatorEnabled = false
    this.store.user = null
    this.store.users = []
    this.store.highlightRole = false
    this.store.menuItems = []
    this.removeTempToken()
    this.clearDatatablesFromLocalStorage()
  }

  public setHighlightRole(val: boolean): void {
    this.store.highlightRole = val
  }

  public get unreadNotifications(): number {
    return this.store.unreadNotifications
  }

  public setSessionTime(): void {
    const date = new Date().getTime()
    document.cookie = `counter=${date}; path=/;`
  }

  public setToken(token: string): void {
    this.store.token = token
    const domain = getDomain()
    document.cookie = `token=${token}; path=/; domain=.${domain};`
    this.store.token = token
  }

  public setTempToken(token: string): void {
    localStorage.setItem(LocalStorageKeys.TEMPORARY_TOKEN, token)
    this.store.token = token
  }

  public removeTempToken(): void {
    this.store.token = ''
    localStorage.removeItem(LocalStorageKeys.TEMPORARY_TOKEN)
  }

  public setAuth(authObject: IAuthAuth): void {
    const token = authObject.token ?? authObject.temp_token
    if (!token) throw new Error('Token is empty')
    this.setToken(token)
    this.setSessionTime()
    this.clearDatatablesFromLocalStorage()
    if (environmentsManager.DISABLE_2FA) return
    this.store.isAuthenticatorEnabled = authObject.isAuthenticatorEnabled
    this.store.isAuthRegenApproved = authObject.regenAuthenticatorApproved
  }

  public setUser(userAuth: IUserAuth): void {
    this.removeTempToken()
    this.store.user = userAuth.user
    if (userAuth.menuItems) this.store.menuItems = userAuth.menuItems
    this.store.isAuthenticatorEnabled =
      userAuth.authorisation.isAuthenticatorEnabled
    this.store.unreadNotifications =
      userAuth.notificationsSummary?.totalUnreadCount ?? 0
  }

  public async verifyCaptcha(
    captchaKey: string,
    userInput: string
  ): Promise<void> {
    await CaptchaRepository.verify(captchaKey, userInput)
      .then((result) => {
        this.setTempToken(result.temporary_token)
      })
      .catch((e) => {
        throw e
      })
  }

  public async login(form: ILoginForm): Promise<void> {
    this.store.reauthPending = true
    const result = await AuthRepository.login(form)
      .then(async (result) => {
        this.setAuth(result.authorisation)
        this.setUser(result)
        return result
      })
      .catch((error) => {
        throw error
      })
      .finally(() => {
        this.store.reauthPending = false
      })

    if (!this.store.user) return
    const disable2FA = environmentsManager.DISABLE_2FA
    if (!disable2FA) return

    if (!result.menuItems) {
      await this.reauth()
      return
    }

    this.store.reauthPending = true
    await UserRepository.getUserPermissions()
      .then((permissions) => {
        this.store.permissions = permissions
      })
      .catch((error) => {
        throw error
      })
      .finally(() => {
        this.store.reauthPending = false
      })
  }

  public async loadUsers(): Promise<void> {
    await UserRepository.getUsers()
      .then((response) => {
        this.store.users = response
      })
      .catch((error) => {
        this.store.users = []
        console.error(error)
      })
  }

  public async reauth(options?: IOptions): Promise<void> {
    this.store.reauthPending = true
    await AuthRepository.reauth(options)
      .then(async (response) => {
        this.setAuth(response.authorisation)
        this.setUser(response)
      })
      .catch((error) => {
        this.logout(options)
        this.store.user = null
        console.error(error)
      })
      .finally(() => {
        this.store.reauthPending = false
      })

    if (!this.store.user) return

    this.store.reauthPending = true
    await UserRepository.getUserPermissions()
      .then((permissions) => {
        this.store.permissions = permissions
      })
      .catch((error) => {
        throw error
      })
      .finally(() => {
        this.store.reauthPending = false
      })
  }

  public async logout(options?: IOptions): Promise<void> {
    // https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
    await AuthRepository.logout(options).finally(() => {
      this.clearUserData()
    })
  }

  public async addRegistration(form: IRegisterForm): Promise<void> {
    this.store.isLoading = true
    await AuthRepository.add(form)
      .then(() => {
        this.store.isLoading = false
      })
      .catch((error: Error | AxiosError) => {
        this.store.isLoading = false
        throw error
      })
  }

  public async updateUserData(
    userId: number,
    phone: string,
    lastName: string
  ): Promise<void> {
    this.store.isLoading = true
    await UserRepository.updateData(userId, phone, lastName)
      .then(() => {
        this.store.isLoading = false
      })
      .catch(() => {
        this.store.isError = true
        throw new Error('Error updating user data')
      })
  }

  public clearDatatablesFromLocalStorage(): void {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (key && key.startsWith('Datatable')) {
        localStorage.removeItem(key)
        i-- // zmniejszamy indeks, ponieważ długość localStorage uległa zmianie
      }
    }
  }

  public checkAccess(permission: IPermArray): boolean {
    if (environmentsManager.PERMISSIONS_ENABLED !== 'true') return true
    return this.store.permissions.some(
      (perm) =>
        perm.domain_number === permission[0] &&
        perm.group_number === permission[1] &&
        perm.number === permission[2] &&
        perm.pivot.access === 1
    )
  }
}
