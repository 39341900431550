export default {
  register: {
    withGoogle: 'Using Google',
    withEmail: 'With email address',
    login: 'Log in',
    create: 'Create an account',
    errorAcceptConditions: 'You must accept the terms of service',
    success: 'Account created successfully',
  },
}
