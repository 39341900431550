export default {
  resetPassword: {
    anonymousReport: 'Anonymous report',
    title: 'Password reset',
    sendButton: 'Send Password Reset Link',
    termsOfService: 'Terms of Service',
    emailSent: 'Email sent',
    checkEmail:
      'Email with password reset link has been sent to your email address.',
    followInstructions:
      'Follow the instructions in the email to reset your password.',
    successReset: 'Password reset successful',
    errorReset: 'Password reset failed',
    resetFor: 'Reset password for',
  },
}
