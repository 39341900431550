export default {
  consents: {
    consents: 'Consents',
    newConsents: 'New Consent',
    editConsents: 'Edit Consent',
    description: 'Description',
    activeConsent: 'Is consent active?',
    name: 'Name',
    yes: 'Yes',
    no: 'No',
    companies: 'Companies',
    previewConsents: 'Preview consent',
    username: 'Username',
    acceptDate: 'Accept date',
    acceptedConsents: 'Accepted consents',
    successUpdate: 'Zgoda została zaktualizowana',
  },
}
