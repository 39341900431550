export default {
  button: {
    save: 'Zapisz',
    delete: 'Usuń',
    cleanFilter: 'Wyczyść filtry',
    apply: 'Zastosuj',
    addApplication: 'Dodaj zgłoszenie',
    addTask: 'Dodaj zadanie',
    addAsset: 'Dodaj aktywo',
    addCompany: 'Dodaj firmę',
    addAttribute: 'Dodaj atrybut',
    editConsent: 'Edytuj zgodę',
    show: 'Pokaż',
    editRole: 'Edytuj rolę',
    deactivate: 'Dezaktywuj',
    editParameter: 'Edytuj parametr',
    addAttachments: 'Dodaj załączniki',
    addGlossary: 'Dodaj słownik',
    block: 'Zablokuj',
    remove: 'Usuń',
    showUserSettings: 'Pokaż ustawienia użytkownika',
    resetTotpAuth: 'Resetuj totp auth',
    back: 'Powrót',
    addConsent: 'Dodaj zgodę',
    addUser: 'Dodaj użytkownika',
    addFiles: 'Dodaj załączniki',
    cancel: 'Anuluj',
    addSubUnit: 'Dodaj podjednostkę',
    addForm: 'Dodaj formularz',
    addSource: 'Dodaj źródło',
    activate: 'Aktywuj',
    add: 'Dodaj',
    addNew: 'Dodaj nowy',
    goTo: 'Przejdź',
    saveChanges: 'Zapisz zmiany',
    addRecord: 'Dodaj rekord',
    addPartialRisk: 'Dodaj szczątkowe ryzyko',
    generateRecord: 'Generuj rekord',
    comeback: 'Wróć',
    continue: 'Kontynuuj',
    saveCriticality: 'Zapisz krytyczność',
    saveRisk: 'Zapisz ryzyko',
    edit: 'Edytuj',
    clickToDel: 'Kliknij aby usunąć',
    addRequest: 'Dodaj wniosek',
    identify: 'Identyfikuj',
    search: 'Szukaj',
    code: 'Kod',
    confirm: 'Potwierdź',
    addAffair: 'Dodaj sprawę',
    close: 'Zamknij',
    takeOver: 'Przejmij',
    delegate: 'Oddeleguj',
    closeTask: 'Zamknij zadanie',
    startProcessing: 'Rozpocznij procesowanie',
    refreshCaptcha: 'Odśwież captcha',
    addNotification: 'Dodaj powiadomienie',
    send: 'Wyślij',
  },
}
