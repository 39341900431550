export default {
  risk: {
    title: 'Ryzyko szczątkowe',
    baseInfo: 'Podstawowe informacje',
    riskForm: 'Formularz ryzyka',
    backToList: 'Powrót do listy ryzyk',
    backToAsset: 'Powrót do aktywa',
    additionalData: {
      description_risk: 'Opis ryzyka',
      criticality_level: 'Poziom krytyczności',
      identification_individual_risk: 'Identyfikacja indywidualnego ryzyka',
      individual_risk_weight: 'Waga ryzyka indywidualnego',
      potential_global_loss: 'Potencjalna strata',
      remedial_actions: 'Działania naprawcze',
      remediation_name: 'Nazwa działania naprawczego',
      remediation_weight: 'Waga działania naprawczego',
      risk_assessment_definition: 'Definicja oceny ryzyka',
      risk_assessment_weight: 'Waga oceny ryzyka',
      risk_level: 'Poziom ryzyka',
      rodo_risk_level: 'Poziom ryzyka rodo',
      rodo_risk_weight: 'Waga ryzyka rodo',
      type_rodo: 'Typ ryzyka rodo',
    },
  },
}
