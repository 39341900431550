export default {
  assets: {
    assets: 'Assets',
    riskLevel: 'Risk level',
    criticality: 'Criticality',
    lowImpact: 'Low Impact',
    relevant: 'Relevant',
    important: 'Important',
    critical: 'Critical',
    introduced: 'Introduced',
    criticalityNotDetermined: 'Criticality not determined',
    duringDevelopment: 'During development',
    low: 'Low',
    acceptable: 'Acceptable',
    highRisk: 'High risk',
    unacceptable: 'Unacceptable',
    systemCriticality: 'System criticality',
    comments: 'Comments',
    materialityOfAsset: 'Materiality of the asset',
  },
}
