// noinspection JSUnusedGlobalSymbols

export type IPermArray = [number, number, number]
/*
Permission documentation:
https://remedia-it.atlassian.net/wiki/spaces/MBGHL/pages/58621954/UprawnieniaatlOrigin=eyJpIjoiODdjYzBkZTVhOTA3NGU3Zjk4NTMxOTM2ZTY0NzVmM2MiLCJwIjoiaiJ9

Permission Array: [Domain, Module, Permission]
Backend Keys: domain_number, group_number, number
*/

export const SettingsUserMenu: IPermArray = [1, 1, 1]
export const SettingsUserBlock: IPermArray = [1, 1, 2]
export const SettingsUserRemove: IPermArray = [1, 1, 3]
export const SettingsUserSettingsPreview: IPermArray = [1, 1, 4]
export const SettingsUserEditRole: IPermArray = [1, 1, 5]
export const SettingsUserEditOrganizationalUnit: IPermArray = [1, 1, 6]
export const SettingsUserEditPermissions: IPermArray = [1, 1, 7]
export const SettingsUserAdd: IPermArray = [1, 1, 8]
export const SettingsGlossariesMenu: IPermArray = [1, 2, 1]
export const SettingsGlossariesAdd: IPermArray = [1, 2, 2]
export const SettingsGlossariesPreview: IPermArray = [1, 2, 3] // brak dostępu do edycji formularza
export const SettingsGlossariesImport: IPermArray = [1, 2, 4] //lack of functionality
export const SettingsCompaniesMenu: IPermArray = [1, 3, 1]
export const SettingsCompaniesAdd: IPermArray = [1, 3, 2]
export const SettingsCompaniesPreview: IPermArray = [1, 3, 3] //cała lista
export const SettingsCompaniesEdit: IPermArray = [1, 3, 4] //slot,prop jeśli jest na true to wyswietlic customowy slot, brak dostępu do edycji firmy
export const SettingsAttributesMenu: IPermArray = [1, 4, 1]
export const SettingsAttributesAdd: IPermArray = [1, 4, 2]
export const SettingsParametersMenu: IPermArray = [1, 5, 1]
export const SettingsParametersEdit: IPermArray = [1, 5, 2] //nie wczytują się
/* Deprecated */
export const SettingsParametersChangeStatus: IPermArray = [1, 5, 3]
export const SettingsRolesMenu: IPermArray = [1, 6, 1]
export const SettingsRolesEdit: IPermArray = [1, 6, 2]
export const SettingsConsentsMenu: IPermArray = [1, 7, 1]
export const SettingsConsentsAdd: IPermArray = [1, 7, 2]
export const SettingsConsentsEdit: IPermArray = [1, 7, 3]
export const SettingsConsentsChangeStatus: IPermArray = [1, 7, 4]
export const SettingsConsentsPreview: IPermArray = [1, 7, 5]
export const SettingsTicketsMenu: IPermArray = [1, 8, 1]

export const SettingsLogPreview: IPermArray = [1, 9, 1]
export const SettingsLogPayloadPreview: IPermArray = [1, 9, 2]
export const TicketsMenu: IPermArray = [2, 1, 1]
export const TicketsCreate: IPermArray = [2, 1, 2]
export const TicketsSourceSelection: IPermArray = [2, 1, 3]
export const TicketsCasePreview: IPermArray = [2, 1, 4]
export const TicketsCaseAssignment: IPermArray = [2, 1, 5]
export const TicketsActivityLogDisplay: IPermArray = [2, 1, 6]
export const TicketsShare: IPermArray = [2, 1, 7]
export const TicketsNewsPreview: IPermArray = [2, 1, 8]
export const TicketsSendMessages: IPermArray = [2, 1, 9]
export const TicketsIdentificationTakeover: IPermArray = [2, 2, 1]
export const TicketsIdentificationAssignment: IPermArray = [2, 2, 2]
export const TicketsIdentificationBegin: IPermArray = [2, 2, 3]
export const TicketsIdentificationConfirm: IPermArray = [2, 2, 4]
export const TicketsIdentificationEnd: IPermArray = [2, 2, 5]
export const TicketsIdentificationCategoryAssignment: IPermArray = [2, 2, 6]
export const TicketsCategoryStartProcess: IPermArray = [2, 3, 1]
export const TicketsCategoryTransferToCoordinator: IPermArray = [2, 3, 2]
export const TicketsCategoryTransferToExpert: IPermArray = [2, 3, 3]
export const TicketsCategoryEndProcess: IPermArray = [2, 3, 4]
export const TicketsCategoryRevokeProcess: IPermArray = [2, 3, 5]
export const TicketsCategoryUploadFile: IPermArray = [2, 3, 6]
