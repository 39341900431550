<template>
  <div class="c-eventsManagement tw-mt-10">
    <div class="tw-mb-10 tw-grid tw-grid-cols-1 tw-gap-6 xl:tw-grid-cols-2">
      <TotalNotifications :is-loading="isLoading" :statistics="entries" />
      <TimeToResolveNotifications
        :is-loading="isLoading"
        :statistics="statistics"
      />
    </div>
    <div class="tw-grid tw-grid-cols-1 tw-gap-6 xl:tw-grid-cols-2">
      <NumberOfNotificationsPerCategory
        :is-loading="isLoading"
        :number-of-notifications-data="numberOfNotificationsData"
      />
      <PendingNotifications :is-loading="isLoading" :tickets="tickets" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import TotalNotifications from '@/components/views/dashboard/eventsManagement/TotalNotifications.vue'
import TimeToResolveNotifications from '@/components/views/dashboard/eventsManagement/TimeToResolveNotifications.vue'
import NumberOfNotificationsPerCategory from '@/components/views/dashboard/eventsManagement/NumberOfNotificationsPerCategory.vue'
import PendingNotifications from '@/components/views/dashboard/eventsManagement/PendingNotifications.vue'
import widgetsRepository from '@/repositories/WidgetsRepository'
import type {
  IWidgetNumberOfTicketsPerCategory,
  IWidgetReportResolutionTime,
  IWidgetReportsBeingResolved,
  IWidgetTotalEntries,
} from '@/types/WidgetTypes'

@Component({
  components: {
    TotalNotifications,
    TimeToResolveNotifications,
    NumberOfNotificationsPerCategory,
    PendingNotifications,
  },
})
export default class EventsManagement extends Vue {
  public isLoading: boolean = false

  public numberOfNotificationsData: IWidgetNumberOfTicketsPerCategory[] = []
  public tickets: IWidgetReportsBeingResolved[] = []
  public statistics: IWidgetReportResolutionTime = {
    minTime: 0,
    avgTime: 0,
    maxTime: 0,
  }
  public entries: IWidgetTotalEntries = {
    ticket_count: 0,
    identified_count: 0,
    incident_count: 0,
  }

  public async mounted(): Promise<void> {
    this.isLoading = true
    await widgetsRepository.getWidgets([6, 3, 2, 1]).then((response) => {
      this.numberOfNotificationsData = []
      this.tickets = []
      this.statistics = {
        minTime: 0,
        avgTime: 0,
        maxTime: 0,
      }
      this.entries = {
        ticket_count: 0,
        identified_count: 0,
        incident_count: 0,
      }
      if (response[6]) {
        this.numberOfNotificationsData = response[6]
      }
      if (response[3]) {
        this.tickets = response[3]
      }
      if (response[2]) {
        this.statistics = response[2]
      }
      if (response[1]) {
        this.entries = response[1]
      }
      this.isLoading = false
    })
  }
}
</script>
