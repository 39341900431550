<template>
  <ConsentsModal v-if="isConsentsModalOpen" />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import UIModal from '@/components/UI/UIModal.vue'
import UIButton from '@/components/UI/UIButton.vue'
import ConsentsModal from '@/components/modals/ConsentsModal.vue'
import { consentsModalService } from '@/main'

@Component({
  components: { UIModal, UIButton, ConsentsModal },
})
export default class GlobalModalController extends Vue {
  public get isConsentsModalOpen(): boolean {
    return consentsModalService.isOpen
  }
}
</script>
