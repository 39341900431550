export default {
  companies: {
    companies: 'Firmy',
    company: 'Firma: ',
    newCompany: 'Dane Firmy',
    name: 'Nazwa',
    companyName: 'Nazwa firmy',
    street: 'Ulica',
    nip: 'NIP',
    town: 'Miasto',
    postcode: 'Kod pocztowy',
    phone: 'Numer telefonu',
    units: 'Jednostki organizacyjne',
    addBaseUnit: 'Dodaj jednostkę główną',
    addChildUnit: 'Dodaj podjednostkę',
    unitWeight: 'Waga',
    newUnit: 'Nowa jednostka',
  },
}
