<template>
  <span
    class="isError badge badge-danger d-block tw-mt-1 !tw-bg-red-600"
    :class="errorClass"
    role="alert"
    v-if="isError && error.length"
  >
    <strong class="tw-text-wrap tw-leading-5">{{ error }}</strong>
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class UIError extends Vue {
  @Prop({ required: false })
  public errorClass!: string

  @Prop({ required: false })
  public error!: string

  @Prop({ required: false })
  public isError!: boolean
}
</script>

<style scoped lang="scss"></style>
