import menu from '@/translations/en/menu'
import buttons from '@/translations/en/buttons'
import general from '@/translations/en/general'
import notifications from '@/translations/en/notifications'

export default {
  ...menu,
  ...buttons,
  ...general,
  ...notifications,
}
