<template>
  <div
    class="tw-rounded-lg tw-bg-gray-100 dark:tw-bg-grey21"
    :class="{
      'tw-p-4': !compact,
      'tw-p-2': compact,
    }"
  >
    <div
      v-if="files.length"
      class="tw-grid tw-gap-4"
      :class="{
        'tw-grid-cols-[repeat(auto-fill,minmax(350px,1fr))] tw-gap-4': !compact,
        'tw-grid-cols-[repeat(auto-fill,minmax(250px,1fr))] tw-gap-2': compact,
      }"
    >
      <div
        v-for="(file, idx) in files"
        :key="idx"
        class="tw-relative tw-flex tw-flex-col tw-rounded-md tw-bg-gray-50 tw-shadow dark:tw-bg-gray-800"
        :class="{
          'tw-p-4': !compact,
          'tw-p-2': compact,
        }"
      >
        <UIButton
          v-if="canDelete"
          button-class="tw-text-black"
          @click="showModal(file)"
          color="red"
          class="tw-absolute tw-right-0 tw-top-0 tw-m-2"
        >
          <template #left>
            <font-awesome-icon
              icon="icon fa-solid fa-trash"
              class="tw-text-lg"
            />
          </template>
        </UIButton>
        <div
          class="tw-grid tw-items-center tw-gap-4"
          :class="{
            'tw-grid-cols-[96px_1fr]': !compact,
            'tw-grid-cols-[36px_1fr]': compact,
          }"
        >
          <div v-if="isImage(file.file)">
            <img
              class="tw-rounded-md tw-border tw-object-cover tw-shadow"
              :class="{
                'tw-h-24 tw-w-24': !compact,
                'tw-h-9 tw-w-9': compact,
              }"
              :src="getFileURL(file.file)"
              :alt="file.fileName"
            />
          </div>
          <div class="tw-flex" v-else>
            <font-awesome-icon
              icon="icon fa-solid fa-file"
              class="tw-text-gray-400"
              :class="{
                'tw-h-16 tw-w-16': !compact,
                'tw-h-9 tw-w-9': compact,
              }"
            />
          </div>
          <div class="tw-space-y-1 tw-self-center">
            <p
              class="tw-break-anywhere tw-mb-0 tw-overflow-ellipsis tw-text-pretty tw-text-sm tw-font-medium"
              :title="file.fileName"
            >
              {{ file.fileName }}
            </p>
            <p class="tw-text-xs tw-text-gray-500">
              {{ formatFileSize(file.file.size) }}
            </p>
          </div>
        </div>
        <UIButton
          class="tw-ml-auto tw-mt-auto tw-h-fit"
          type="secondary"
          :label="$t('common.files.download')"
          @click="downloadFile(file.file)"
        />
      </div>
    </div>
    <div v-else class="tw-rounded-md tw-py-12 tw-text-center">
      <font-awesome-icon
        icon="icon fa-solid fa-folder"
        class="tw-h-16 tw-w-16 tw-text-gray-400"
      />
      <h3 class="tw-mt-2 tw-text-sm tw-font-medium">
        {{ $t('common.files.noFiles') }}
      </h3>
      <p class="tw-mt-1 tw-text-sm">{{ $t('common.files.noFilesDesc') }}</p>
    </div>

    <ConfirmActionModal
      :is-visible="isModalVisible"
      :function-call="functionCall"
      :label="$t('common.files.deleteFileConfirmation')"
      @close="closeModal"
    />
  </div>
</template>

<script lang="ts">
import { Prop, Vue, Component } from 'vue-facing-decorator'
import type { IFilePreview } from '@/types/FileUploadTypes'
import UIButton from '@/components/UI/UIButton.vue'
import ConfirmActionModal from '@/components/modals/ConfirmActionModal.vue'
import type { Nullable } from '@/types/Nullable'
import FilesRepository from '@/repositories/FilesRepository'
import { toast } from 'vue3-toastify'

@Component({
  components: { ConfirmActionModal, UIButton },
  emits: ['file-deleted'],
})
export default class FilePreview extends Vue {
  @Prop({ type: Array, required: true }) files!: IFilePreview[]
  @Prop({ required: false, default: false }) canDelete!: boolean
  @Prop({ required: false, default: false }) compact!: boolean

  public isModalVisible: boolean = false
  public functionCall: Nullable<() => void> = null

  public isImage(file: File): boolean {
    return (
      file.type.includes('jpeg') ||
      file.type.includes('png') ||
      file.type.includes('gif')
    )
  }

  public formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
  }

  public closeModal(): void {
    this.isModalVisible = false
    this.functionCall = null
  }

  public downloadFile(file: File): void {
    const link = document.createElement('a')
    link.href = URL.createObjectURL(file)
    link.download = file.name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  public showModal(file: IFilePreview): void {
    this.isModalVisible = true
    this.functionCall = (): void | Promise<void> => this.deleteFile(file)
  }

  public getFileURL(file?: File): string {
    if (!file) return ''
    return URL.createObjectURL(file)
  }
  public deleteFile(file: IFilePreview): void {
    FilesRepository.deleteFile(file.id).then(() => {
      toast.success(this.$t('common.files.fileDeleted'))
      this.$emit('file-deleted', file.id)
    })
  }
}
</script>
