import { Store, Pinia } from 'pinia-class-component'
import type { ReportsDefinition, ReportsStore } from '@/types/ReportsTypes'
import ReportsRepository from '@/repositories/ReportsRepository'

@Store
export default class ReportsService extends Pinia {
  public store: ReportsStore = {
    reportsDefinition: [],
    reportsDefinitionExtensions: [],
  }

  public get reportsDefinition(): ReportsDefinition[] {
    return this.store.reportsDefinition
  }

  public get reportsDefinitionExtensions(): string[] {
    return this.store.reportsDefinitionExtensions
  }

  public async loadReportDefinitions(): Promise<void> {
    this.store.isLoading = true

    await ReportsRepository.getReportsDefinitions()
      .then((response) => {
        this.store.reportsDefinition = response
      })
      .catch(() => {
        this.store.reportsDefinition = []
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadReportDefinitionsExtensions(
    reportType?: string
  ): Promise<void> {
    this.store.isLoading = true

    await ReportsRepository.getReportsDefinitionsExtensions(reportType)
      .then((response) => {
        this.store.reportsDefinitionExtensions = response
      })
      .catch(() => {
        this.store.reportsDefinitionExtensions = []
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }
}
