<template>
  <tr>
    <td colspan="100%">
      <div class="tw-flex tw-justify-center tw-overflow-hidden tw-p-4">
        <BaseLoader />
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import BaseLoader from '@/components/common/BaseLoader.vue'

@Component({
  components: {
    BaseLoader,
  },
})
export default class LoaderTableRow extends Vue {}
</script>
