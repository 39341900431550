export default {
  decimal: '',
  emptyTable: 'No data available in table',
  info: 'Showing _START_ to _END_ of _TOTAL_ entries',
  infoEmpty: 'Showing 0 to 0 of 0 entries',
  infoFiltered: '(filtered from _MAX_ total entries)',
  infoPostFix: '',
  thousands: ',',
  lengthMenu: 'Show _MENU_ entries',
  loadingRecords: 'Loading...',
  processing: '',
  search: 'Search:',
  zeroRecords: 'No matching records found',
  aria: {
    orderable: 'Order by this column',
    orderableReverse: 'Reverse order this column',
    sortAscending: ': activate to sort column ascending',
    sortDescending: ': activate to sort column descending',
  },
}
