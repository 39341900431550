<template>
  <div
    class="c-tasksFilters tw-absolute !tw-left-0 tw-z-10 tw-rounded-lg tw-bg-white tw-px-6 tw-pb-2 tw-pt-6 tw-shadow-lg xl:!-tw-left-[138px] xl:tw-w-[714px] dark:tw-bg-neutral-800"
  >
    <div
      class="tw-grid-col-1 tw-grid tw-gap-4 sm:tw-grid-cols-2 xl:tw-grid-cols-3"
    >
      <div>
        <p class="tw-font-bold">
          {{ $t('common.type') }}
        </p>
        <div v-for="type in typeFilter" :key="type.value">
          <UICheckbox
            v-model="checkboxType[type.value]"
            :name="`typeFilter_${type.value}`"
          >
            {{ type.label }}
          </UICheckbox>
        </div>
      </div>
      <div>
        <p class="tw-font-bold">{{ $t('common.status') }}</p>
        <div v-for="type in statusFilter" :key="type.value">
          <UICheckbox
            v-model="checkboxStatus[type.value]"
            :name="`statusFilter_${type.value}`"
          >
            {{ type.label }}
          </UICheckbox>
        </div>
      </div>
      <div>
        <p class="tw-font-bold">
          {{ $t('views.tasks.connections') }}
        </p>
        <div
          v-for="type in ownershipFilter"
          :key="`ownershipFilter_${type.value}`"
        >
          <UICheckbox
            v-model="checkboxOwnership[type.value]"
            :name="type.value"
          >
            {{ type.label }}
          </UICheckbox>
        </div>
      </div>
    </div>
    <div class="tw-mt-3 tw-flex tw-justify-between">
      <UIButton
        :label="$t('button.cleanFilter')"
        size="medium"
        type=""
        button-class="tw-h-10 tw-mt-1 tw-uppercase tw-text-xs tw-font-semibold tw-border tw-border-pinkd8 tw-text-pinkd8 tw-px-2 tw-rounded-lg hover:tw-border-pinkd7"
        @click="clearFilters()"
      ></UIButton>
      <UIButton
        type="primary"
        :label="$t('button.apply')"
        size="medium"
        button-class="tw-mt-1"
      ></UIButton>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import UIButton from '@/components/UI/UIButton.vue'
import UICheckbox from '@/components/UI/UICheckbox.vue'
import UIStatus from '@/components/UI/UIStatus.vue'
import type { ITab } from '@/types/TabsTypes'

@Component({
  components: {
    UIStatus,
    UICheckbox,
    UIButton,
  },
})
export default class Filters extends Vue {
  @Prop({ required: true })
  public statusFilter: ITab[]

  @Prop({ required: true })
  public typeFilter: ITab[]

  @Prop({ required: true })
  public ownershipFilter: ITab[]

  public checkboxStatus: Record<string, boolean> = {}
  public checkboxOwnership: Record<string, boolean> = {}
  public checkboxType: Record<string, boolean> = {}

  public clearFilters(): void {
    this.checkboxStatus = {}
    this.checkboxOwnership = {}
    this.checkboxType = {}
  }
}
</script>
