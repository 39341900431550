export default {
  datatables: {
    processing: 'Przetwarzanie...',
    search: 'Szukaj:',
    lengthMenu: 'Pokaż _MENU_ pozycji',
    info: 'Pozycje od _START_ do _END_ z _TOTAL_ łącznie',
    infoEmpty: 'Pozycji 0 z 0 dostępnych',
    infoFiltered: '(filtrowanie spośród _MAX_ dostępnych pozycji)',
    loadingRecords: 'Wczytywanie...',
    zeroRecords: 'Nie znaleziono pasujących pozycji',
    emptyTable: 'Brak danych',
    aria: {
      orderable: 'Sortuj wg tej kolumny',
      orderableReverse: 'Odwróc kolejność tej kolumny',
      sortAscending: ': aktywuj, by posortować kolumnę rosnąco',
      sortDescending: ': aktywuj, by posortować kolumnę malejąco',
    },
    copy: 'Kopiuj do schowka',
    copyTooltip: 'Kopiuj do schowka w formie tekstu',
    csv: 'Eksport do CSV',
    csvTooltip: 'Eksportuj do pliku CSV',
  },
}
