import { createRouter, createWebHistory } from 'vue-router'
import { environmentsManager, userService } from '@/main'
import { getTokenCookie } from '@/helpers/getTokenCookie'
import { routes } from './routes'
import { routeNames } from './types'
import type { Nullable } from 'vitest'

const router = createRouter({
  linkActiveClass: 'active',
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
})

router.beforeEach(async (to) => {
  if (to.meta.auth) {
    const token = getTokenCookie(document.cookie)

    if (token.length === 0) {
      return '/login'
    }

    if (!userService.store.user) {
      await userService.reauth({ ignoreToast: true })
      if (!userService.store.user) {
        return '/login'
      }
    }

    /* const user = userService.getUser */
    /* const isVerified = Boolean(user?.activated) */
    const isVerified = true
    if (to.name !== routeNames.Verify && !isVerified) {
      return '/verify'
    }
  }
  const metaPermission = to.meta.permission as Nullable<string>
  if (metaPermission && environmentsManager.PERMISSIONS_ENABLED !== 'false') {
    const permission = userService.menuItems[metaPermission]
    if (!permission || !permission.active) {
      return { name: routeNames.NoAccess }
    }
  }

  if (to.meta.toDashboard && userService.getTokenCookie().length > 0) {
    return { name: routeNames.Dashboard }
  }
})

export default router
