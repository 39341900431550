export default {
  menu: {
    title: 'Menu',
    titleSettings: 'Ustawienia',
    tickets: 'Zgłoszenia',
    tasks: 'Zadania',
    calendar: 'Kalendarz',
    assets: 'Aktywa',
    users: 'Użytkownicy',
    glossaries: 'Słowniki',
    companies: 'Firmy',
    attributes: 'Atrybuty',
    parameters: 'Parametry',
    roles: 'Role',
    consents: 'Zgody',
    profileSettings: 'Ustawienia konta',
    risks: 'Ryzyka',
    identification: 'Identyfikacja',
    affairs: 'Sprawy',
    sources: 'Źródła',
    notificationsMenu: 'Powiadomienia',
    reports: 'Raporty',
    selectRole: 'Wybierz rolę',
    selectUnit: 'Wybierz jednostkę',
    organization: 'Organizacja',
    ticketSettings: 'Ustawienia zgłoszeń',
    notifications: {
      added: 'Atrybut został dodany',
      changed: 'Atrybut został zmieniony',
    },
  },
}
