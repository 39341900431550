export default {
  dashboard: {
    totalNotifications: 'Zgłoszenia ogółem',
    timeToResolveNotifications: 'Czas rozwiązywania zgłoszeń',
    numberOfNotificationsPerCategory: 'Ilość zgłoszeń per kategoria',
    pendingNotifications: 'Zgłoszenia w trakcie rozwiązywania',
    baselineThreats: 'Zagrożenia bazowe',
    assetsByCriticality: 'Aktywa wg krytyczności',
    openNotifications: 'Otwarte zgłoszenia',
    riskCategory: 'Kategorie ryzyka',
    riskMap: 'Mapa ryzyka',
    numberOfNotifications: 'Ilość zgłoszeń',
    numberIdentified: 'Ilość zidentyfikowanych',
    numberOfIncidents: 'Ilość incydentów',
    min: 'Minimalny',
    medium: 'Średni',
    max: 'Maksymalny',
    lowImpact: 'Mały wpływ',
    relevant: 'Istotny',
    important: 'Ważny',
    critical: 'Krytyczny',
    criticality: 'Krytyczność',
    assetCriticality: 'Krytyczność aktywa',
    notificationID: 'ID zgłoszenia',
    dateOfNotification: 'Data zgłoszenia',
    status: 'Status',
    responsiblePerson: 'Odpowiedzialny',
    threat: 'Zagrożenie',
    level: 'Poziom',
    trend: 'Trend',
    attacksOnInfrastructure: 'Ataki na Infrastrukturę',
    unintendedDamages: 'Szkody niezamierzone (przypadkowe)',
    damageLossOfITResources: 'Uszkodzenie/utrata (zasoby IT)',
    humanResources: 'Zasoby ludzkie',
    failuresOutages: 'Awarie/Przestoje',
    failuresDefects: 'Awarie/Usterki',
    physicalTechnicalSecurity: 'Bezpieczeństwo fizyczne i techniczne',
    informationSecurity: 'Bezpieczeństwo informacji',
    disasters: 'Katastrofy',
    lawViolations: 'Łamanie Prawa',
    unethicalActionsAbuses: 'Nieetyczne działania/nadużycia',
    eavesdroppingInterceptionSeizure:
      'Podsłuchiwanie/przechwytywanie/przejęcie kontroli',
    low: 'Niski',
    acceptable: 'Akceptowalny',
    highRisky: 'Wysokiego ryzyka',
    unacceptable: 'Nieakceptowalny',
    threatLevel: 'Poziom zagrożenia',
  },
}
