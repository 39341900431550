export default {
  parameters: {
    parameters: 'Parametry',
    type: {
      users: 'Użytkownicy',
      companies: 'Firmy',
      company_units: 'Jednostki',
      tickets: 'Zgłoszenia',
      ticket_sources: 'Źródła zgłoszeń',
      roles: 'Role',
      data: 'Dane',
    },

    modalEdit: {
      editParameter: 'Edytuj parametr',
      notificationSuccess: 'Parameter zostal  zaktualizowany pomyślnie',
      form: {
        value: 'Wartość',
      },
      buttons: {
        save: 'Zapisz',
        cancel: 'Anuluj',
      },
      title: {
        1: 'Nadaj domyślną firmę',
        2: 'Nadaj domyślną jednostkę firmy',
        3: 'Nadaj domyślną rolę',
        4: 'Nadaj domyślną firmę dla anonimowego zgłoszenia',
        5: 'Nadaj domyślną jednostkę firmy dla anonimowego zgłoszenia',
        6: 'Ustaw domyślny czas wygaśnięcia anonimowego zgłoszenia',
        8: 'Ustaw domyślny czas wygaśnięcia sesji',
        9: 'Ustaw domyślne źródło zgłoszenia',
        10: 'Ustaw domyślny termin przeczytania zgłoszenia',
        11: 'Ustaw domyślny czas udostępnienia zgłoszenia',
        12: 'Ustaw domyślny priorytet powiadomienia zgłoszenia',
      },
    },
    label: {},
  },
}
