import client from '@/services/client'
import { isAxiosError } from 'axios'
import { errorsService } from '@/main'
import { errorsEnum } from '@/types/ErrorTypes'
import {
  type IAttachementConfig,
  type IAttachementTypes,
  IAttachemenTypeId,
  type IFile,
  type IFileListItem,
  type IMimeConfig,
} from '@/types/FileUploadTypes'

class FilesRepository {
  public async getMimeTypes(): Promise<IMimeConfig> {
    return await client
      .get<IMimeConfig>('files/mime-configs')
      .then((response) => {
        return response.data
      })
      .catch((e) => {
        if (isAxiosError(e)) {
          if (!e.response) throw e
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetMimeTypes,
            response: e.response,
          })
        }
        throw e
      })
  }

  public async getAttachementConfig(): Promise<IAttachementConfig> {
    return await client
      .get<IAttachementConfig>('files/attachment-configs')
      .then((response) => {
        return response.data
      })
      .catch((e) => {
        if (isAxiosError(e)) {
          if (!e.response) throw e
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetAttachementConfig,
            response: e.response,
          })
        }
        throw e
      })
  }

  public async uploadFile(
    files: Blob[],
    type: IAttachementTypes,
    id: number,
    removeMeta: boolean = false
  ): Promise<string> {
    const formData = new FormData()

    files.forEach((file) => {
      formData.append('files[]', file)
    })

    formData.append('attachmentType', type)
    formData.append('attachmentId', String(id))
    formData.append('removeMeta', removeMeta ? '1' : '0')

    return await client
      .post<string>('files/storage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response.data
      })
      .catch((e) => {
        if (isAxiosError(e)) {
          if (!e.response) throw e
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.UploadFile,
            response: e.response,
          })
        }
        throw e
      })
  }

  public async getFilesByTypeAndId(
    type: IAttachementTypes,
    id: number
  ): Promise<IFileListItem[]> {
    const filterBy = [
      {
        attachment_type: IAttachemenTypeId[type],
        attachment_id: id,
      },
    ]
    const acc = [filterBy]
    const payload = new URLSearchParams()
    payload.append('filterBy', JSON.stringify(acc))

    return await client
      .get<IFileListItem[]>(`files`, { params: payload })
      .then((response) => {
        return response.data
      })
      .catch((e) => {
        if (isAxiosError(e)) {
          if (!e.response) throw e
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetFilesByTypeAndId,
            response: e.response,
          })
        }
        throw e
      })
  }

  public async getFilesByIds(ids: number[]): Promise<IFile[]> {
    const payload = new URLSearchParams()
    ids.forEach((id) => {
      payload.append('fileIds[]', String(id))
    })

    return await client
      .get<IFile[]>(`files/storage`, { params: payload })
      .then((response) => {
        return response.data
      })
      .catch((e) => {
        if (isAxiosError(e)) {
          if (!e.response) throw e
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetFilesByIds,
            response: e.response,
          })
        }
        throw e
      })
  }

  public async deleteFile(id: number): Promise<void> {
    return await client
      .delete(`files/${id}`)
      .then(() => {
        return
      })
      .catch((e) => {
        if (isAxiosError(e)) {
          if (!e.response) throw e
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.DeleteFile,
            response: e.response,
          })
        }
        throw e
      })
  }
}

export default new FilesRepository()
