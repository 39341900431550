<template>
  <div class="c-timeToResolveNotifications">
    <DashboardBox
      :title="$t('views.dashboard.timeToResolveNotifications')"
      :is-loading="isLoading"
    >
      <slot>
        <div class="tw-flex tw-justify-center tw-p-4">
          <UIChartBar
            :data="timeToResolveNotificationsData"
            :custom-options="options"
          />
        </div>
      </slot>
    </DashboardBox>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Setup, Vue } from 'vue-facing-decorator'
import UIChartBar from '@/components/UI/UIChartBar.vue'
import DashboardBox from '@/components/views/dashboard/DashboardBox.vue'
import type { IChartData } from '@/types/ChartsTypes'
import type { ChartOptions } from 'chart.js'
import { type BasicColorMode, useColorMode } from '@vueuse/core'
import type { IWidgetReportResolutionTime } from '@/types/WidgetTypes'

@Component({
  components: { UIChartBar, DashboardBox },
})
export default class TimeToResolveNotifications extends Vue {
  @Setup(() =>
    useColorMode({
      attribute: 'data-bs-theme',
    })
  )
  public mode!: BasicColorMode

  @Prop({ required: true })
  public isLoading!: boolean

  @Prop({ required: true })
  public statistics!: IWidgetReportResolutionTime

  public get timeToResolveNotificationsData(): IChartData {
    return {
      labels: [
        this.$t('views.dashboard.min'),
        this.$t('views.dashboard.medium'),
        this.$t('views.dashboard.max'),
      ],
      datasets: [
        {
          data: [
            this.statistics.minTime,
            this.statistics.avgTime,
            this.statistics.maxTime,
          ],
          backgroundColor: ['#e5e6eb', '#ffd553', '#ff920f'],
        },
      ],
    }
  }
  public get options(): ChartOptions<'bar'> {
    return {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            color: this.mode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'black', // for dark mode
          },
        },
        x: {
          ticks: {
            color: this.mode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'black', // for dark mode
          },
        },
      },
      plugins: {
        legend: {
          display: false,
          labels: {
            color: this.mode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'black', // for dark mode
          },
        },
      },
    }
  }
}
</script>
