export function getDomain(): string {
  const domain = window.location.hostname
  const parts = domain.split('.')

  const isIpAddress = /^\d{1,3}(\.\d{1,3}){3}$/.test(domain)
  if (isIpAddress) {
    return domain
  }

  // getting last two parts of domain
  return parts.slice(-2).join('.')
}
