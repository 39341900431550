<template>
  <div class="c-riskManagement tw-mt-10">
    <div
      class="tw-mb-10 tw-grid tw-grid-cols-1 tw-gap-6 xl:tw-grid-cols-2 2xl:tw-grid-cols-3"
    >
      <BaselineThreats
        :is-loading="isLoading"
        :baseline-threats="baselineThreats"
      />
      <AssetsByCriticality
        :is-loading="isLoading"
        :statistics="assetsByCriticality"
      />
      <OpenNotifications :is-loading="isLoading" :statistics="openTickets" />
    </div>
    <div class="tw-grid tw-grid-cols-1 tw-gap-6 xl:tw-grid-cols-2">
      <RiskCategory
        :is-loading="isLoading"
        :category-risk="categoryRisk"
        v-if="!isLoading"
      />
      <RiskMap :is-loading="isLoading" :statistics="riskMap" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import BaselineThreats from '@/components/views/dashboard/riskManagement/BaselineThreats.vue'
import AssetsByCriticality from '@/components/views/dashboard/riskManagement/AssetsByCriticality.vue'
import OpenNotifications from '@/components/views/dashboard/riskManagement/OpenNotifications.vue'
import RiskCategory from '@/components/views/dashboard/riskManagement/RiskCategory.vue'
import RiskMap from '@/components/views/dashboard/riskManagement/RiskMap.vue'
import type {
  IWidgetAssetsByCriticality,
  IWidgetBaselineThreats,
  IWidgetCategoryRisk,
  IWidgetOpenTickets,
  IWidgetRiskMap,
} from '@/types/WidgetTypes'
import widgetsRepository from '@/repositories/WidgetsRepository'

@Component({
  components: {
    RiskMap,
    RiskCategory,
    BaselineThreats,
    AssetsByCriticality,
    OpenNotifications,
  },
})
export default class RiskManagement extends Vue {
  public isLoading: boolean = false
  public riskMap: IWidgetRiskMap[] = []
  public openTickets: IWidgetOpenTickets[] = []
  public assetsByCriticality: IWidgetAssetsByCriticality[] = []
  public categoryRisk: IWidgetCategoryRisk = {}
  public baselineThreats: IWidgetBaselineThreats[] = []

  public async mounted(): Promise<void> {
    this.isLoading = true
    await widgetsRepository.getWidgets([4, 5, 7, 8, 9]).then((response) => {
      this.assetsByCriticality = []
      this.openTickets = []
      this.riskMap = []
      this.categoryRisk = {}
      this.baselineThreats = []

      if (response[4]) {
        this.assetsByCriticality = response[4]
      }
      if (response[5]) {
        this.openTickets = response[5]
      }
      if (response[7]) {
        this.riskMap = response[7]
      }
      if (response[8]) {
        this.categoryRisk = response[8]
      }
      if (response[9]) {
        this.baselineThreats = response[9]
      }
      this.isLoading = false
    })
  }
}
</script>
