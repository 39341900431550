import client from '@/services/client'
import { isAxiosError } from 'axios'
import { errorsService } from '@/main'
import { errorsEnum } from '@/types/ErrorTypes'
import type { IWidgets } from '@/types/WidgetTypes'

class WidgetsRepository {
  public async getWidgets(ids: number[]): Promise<IWidgets> {
    const query = ids.join('&widgetIds[]=')
    return await client
      .get(`dashboards/widgets?widgetIds[]=${query}`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetManyConsents,
            response: error.response,
          })
        }
        throw error
      })
  }
}

export default new WidgetsRepository()
