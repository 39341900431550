<template>
  <template v-if="access">
    <slot />
  </template>
  <template v-else-if="customContentNoAccess">
    <slot name="no-access" />
  </template>
  <span class="tw-hidden" :title="title" v-else />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { type IPermArray } from '@/types/PermissionsModules'
import { environmentsManager, i18n, userService } from '@/main'
@Component({})
export default class AccessContainer extends Vue {
  @Prop({ required: true }) public permission!: IPermArray
  @Prop({ required: false, default: false }) public disabledPermission!: boolean

  @Prop({ required: false, default: false })
  public customContentNoAccess!: boolean

  public get disabledEnvPermission(): boolean {
    return environmentsManager.PERMISSIONS_ENABLED === 'false'
  }

  public get access(): boolean {
    if (this.disabledPermission || this.disabledEnvPermission) return true
    return userService.checkAccess(this.permission)
  }

  public get title(): string {
    const t = i18n.global.t
    return this.access ? '' : t('common.noAccess')
  }
}
</script>
