export default {
  glossaries: {
    glossaries: 'Glossaries',
    glossariesImport: 'Import glossaries',
    overwriting: 'Overwriting',
    glossaryType: 'Type of glossary',
    identification: 'Identification',
    system: 'System',
    assetCategory: 'Asset category',
    risky: 'Risky',
    tasks: 'Tasks',
    formType: 'Form type',
    startOfForm: 'Form start',
  },
}
