import { Store, Pinia } from 'pinia-class-component'

interface ILoaderStore {
  showLoadingRequest: boolean
}

@Store
export default class LoaderService extends Pinia {
  public store: ILoaderStore = {
    showLoadingRequest: false,
  }

  public get showLoadingRequest(): boolean {
    return this.store.showLoadingRequest
  }

  public setLoadingRequest(value: boolean): void {
    this.store.showLoadingRequest = value
  }
}
