<template>
  <div class="c-totalNotifications">
    <DashboardBox
      :title="$t('views.dashboard.totalNotifications')"
      :is-loading="isLoading"
    >
      <slot>
        <div class="tw-flex tw-justify-center tw-p-4">
          <UIChartBar
            :data="totalNotificationsData"
            :custom-options="options"
          />
        </div>
      </slot>
    </DashboardBox>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Setup, Vue } from 'vue-facing-decorator'
import DashboardBox from '@/components/views/dashboard/DashboardBox.vue'
import UIChartBar from '@/components/UI/UIChartBar.vue'
import type { IChartData } from '@/types/ChartsTypes'
import type { ChartOptions } from 'chart.js'
import { type BasicColorMode, useColorMode } from '@vueuse/core'
import type { IWidgetTotalEntries } from '@/types/WidgetTypes'

@Component({
  components: { UIChartBar, DashboardBox },
})
export default class TotalNotifications extends Vue {
  @Setup(() =>
    useColorMode({
      attribute: 'data-bs-theme',
    })
  )
  public mode!: BasicColorMode

  @Prop({ required: true })
  public isLoading!: boolean

  @Prop({ required: true })
  public statistics!: IWidgetTotalEntries

  public get totalNotificationsData(): IChartData {
    return {
      labels: [
        this.$t('views.dashboard.numberOfNotifications'),
        this.$t('views.dashboard.numberIdentified'),
        this.$t('views.dashboard.numberOfIncidents'),
      ],
      datasets: [
        {
          data: [
            this.statistics.ticket_count,
            this.statistics.identified_count,
            this.statistics.incident_count,
          ],
          backgroundColor: ['#ff6384', '#36a2eb', '#e5e6eb'],
        },
      ],
    }
  }
  public get options(): ChartOptions<'bar'> {
    return {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            color: this.mode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'black', // for dark mode
          },
        },
        x: {
          ticks: {
            color: this.mode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'black', // for dark mode
          },
        },
      },
      plugins: {
        legend: {
          display: false,
          labels: {
            color: this.mode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'black', // for dark mode
          },
        },
      },
    }
  }
}
</script>
