import { errorsService } from '@/main'
import client from '@/services/client'
import type { IAttribute, IAttributeForm } from '@/types/AttributesTypes'
import { errorsEnum } from '@/types/ErrorTypes'
import type { Nullable } from '@/types/Nullable'
import { isAxiosError } from 'axios'
import type {
  IAttributePayload,
  IAttributeValuesResult,
} from '@/types/AttributesTypes'

class AttributesRepository {
  public async getAttributes(): Promise<IAttribute[]> {
    return await client
      .get<IAttribute[]>(`attributes`)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return []
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetManyAttributes,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getAttribute(id: number): Promise<Nullable<IAttribute>> {
    return await client
      .get(`attributes/${id}`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetOneAttribute,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async add(form: IAttributeForm): Promise<Nullable<IAttribute>> {
    return await client
      .post(`attributes`, form)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.AddAttributes,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async update(
    id: number,
    form: IAttributeForm
  ): Promise<Nullable<IAttribute>> {
    return await client
      .patch(`attributes/${id}`, form)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.UpdateAttributes,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async addTicketAttribute(
    payload: IAttributePayload[]
  ): Promise<IAttribute> {
    return await client
      .post<IAttribute>(`attributes/values`, payload)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.AddTicketAttribute,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getAttributeValues(
    payload: URLSearchParams
  ): Promise<IAttributeValuesResult[]> {
    return await client
      .get<IAttributeValuesResult[]>(`attributes/values`, {
        params: payload,
      })
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return []
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetAttributeValues,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async removeAttributeValues(id: number | string): Promise<void> {
    return await client
      .delete(`attributes/values/${id}`)
      .then(() => {
        return
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.UpdateAttributes,
            response: error.response,
          })
        }
        throw error
      })
  }
}

export default new AttributesRepository()
