<template>
  <div
    :class="['tw-flex tw-items-center tw-justify-center', loaderClass]"
    v-show="isLoading"
  >
    <BaseLoader :size="size" />
  </div>
  <div v-show="!isLoading">
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator'
import BaseLoader, {
  type IUILoaderSizes,
} from '@/components/common/BaseLoader.vue'

@Component({ components: { BaseLoader } })
export default class Loader extends Vue {
  @Prop({ default: false }) isLoading!: boolean
  @Prop({ default: 'tw-4' }) loaderClass!: string
  @Prop({ default: 'big' }) size: IUILoaderSizes
}
</script>
