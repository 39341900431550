import { Store, Pinia } from 'pinia-class-component'

interface EnvironmentStore {
  DISABLE_2FA: string
  CONTEXT_ENABLED: string
  SESSION_LIFETIME: number
  MODAL_SHOW_LIFETIME: number
  ANONYMOUS_SESSION: number
  PERMISSIONS_ENABLED: string
  BACKEND_PERMISSIONS_ENABLED: string
  VERSION_NOTIFICATION: string
}

function getEnv(key: string, defaultValue: string): string {
  return import.meta.env[key] || defaultValue
}

@Store
export default class EnvironmentsManager extends Pinia {
  public store: EnvironmentStore = {
    DISABLE_2FA: getEnv('VITE_DISABLE_2FA', 'true'),
    VERSION_NOTIFICATION: getEnv('VERSION_NOTIFICATION', 'true'),
    CONTEXT_ENABLED: getEnv('VITE_CONTEXT_ENABLED', 'true'),
    SESSION_LIFETIME: parseInt(getEnv('VITE_SESSION_LIFETIME', '50')),
    MODAL_SHOW_LIFETIME: parseInt(getEnv('VITE_MODAL_SHOW_LIFETIME', '1')),
    ANONYMOUS_SESSION: parseInt(getEnv('VITE_ANONYMOUS_SESSION', '60')),
    PERMISSIONS_ENABLED: getEnv('VITE_PERMISSIONS_ENABLED', 'true'),
    BACKEND_PERMISSIONS_ENABLED: getEnv(
      'VITE_BACKEND_PERMISSIONS_ENABLED',
      'true'
    ),
  }

  public get DISABLE_2FA(): boolean {
    return this.store.DISABLE_2FA === 'true'
  }

  public get CONTEXT_ENABLED(): string {
    return this.store.CONTEXT_ENABLED
  }

  public get SESSION_LIFETIME(): number {
    return this.store.SESSION_LIFETIME
  }

  public get MODAL_SHOW_LIFETIME(): number {
    return this.store.MODAL_SHOW_LIFETIME
  }

  public get ANONYMOUS_SESSION(): number {
    return this.store.ANONYMOUS_SESSION
  }

  public get PERMISSIONS_ENABLED(): string {
    return this.store.PERMISSIONS_ENABLED
  }

  public get BACKEND_PERMISSIONS_ENABLED(): string {
    return this.store.BACKEND_PERMISSIONS_ENABLED
  }

  public get VERSION_NOTIFICATION(): string {
    return this.store.VERSION_NOTIFICATION
  }
}
