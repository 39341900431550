import type { ObjectKeys, ObjectValues } from '@/types/HelperTypes'
import type { Nullable } from 'vitest'

export type IMimeTypes = 'xls' | 'doc' | 'pdf' | 'image'
export type IAttachementTypes = 'ticket' | 'process' | 'consent' | 'report'

export const IAttachemenTypeId = {
  ticket: 1,
  process: 2,
  consent: 3,
  report: 4,
} as const

export interface IOptions {
  removeMeta: boolean
  mimes: string[]
}

// interface doesn't work
// with `key in type`
export type IMimeConfig = {
  [key in IMimeTypes]: IOptions
}

export interface AttachementConfig {
  maxFiles: number
  formats: Array<IMimeTypes>
  maxFileSize: number
}

export type IAttachementConfig = {
  [key in IAttachementTypes]: AttachementConfig
}

export interface IFileListItem {
  id: number
  uploader_id: Nullable<number>
  name: string
  format: string
  name_hash: string
  attachment_id: ObjectValues<typeof IAttachemenTypeId>
  attachment_type: ObjectKeys<typeof IAttachemenTypeId>
  created_at: string
  updated_at: Nullable<string>
}

export interface IFile {
  fileId: number
  file: string
  contentType: string
}

export interface IFilePreview {
  id: number
  attachment_id: number
  attachment_type: string
  fileName: string
  format: string
  file: File
}
