<script setup lang="ts">
import { RouterView } from 'vue-router'
import { useColorMode } from '@vueuse/core'

useColorMode({
  attribute: 'data-bs-theme',
})
</script>

<template>
  <RouterView />
</template>
