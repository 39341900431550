/* Converts a base64 string to a File object.
 *
 * @param {string} base64String - The base64 encoded string representing the file data.
 * @param {string} filename - The name of the file to be created.
 * @param {string} mimeType - The MIME type of the file to be created.
 * @returns {File} - The created File object.
 */
export function base64ToFile(
  base64String: string,
  filename: string,
  mimeType: string
): File {
  // Remove the data URL prefix if present
  const base64Data = base64String.replace(/^data:image\/\w+;base64,/, '')

  // Decode the base64 string
  const byteCharacters = atob(base64Data)

  // Create byte arrays
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)

  // Create Blob and File
  const blob = new Blob([byteArray], { type: mimeType })
  return new File([blob], filename, { type: mimeType })
}
