<template>
  <div :class="['c-uiChartDoughnut tw-h-[300px] tw-py-6', containerClass]">
    <V-Doughnut :data="data" :options="options" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Setup, Vue } from 'vue-facing-decorator'
import type { ChartOptions } from 'chart.js'
import { type BasicColorMode, useColorMode } from '@vueuse/core'

interface IData {
  labels: string[]
  datasets: { data: number[]; backgroundColor: string[] }[]
}

@Component({})
export default class UIChartDoughnut extends Vue {
  @Prop({ required: false })
  public containerClass: string = ''

  @Prop({ required: true })
  public data: IData = {
    labels: [],
    datasets: [],
  }

  @Setup(() =>
    useColorMode({
      attribute: 'data-bs-theme',
    })
  )
  public mode!: BasicColorMode

  public get options(): ChartOptions<'doughnut'> {
    return {
      responsive: true,
      maintainAspectRatio: false,
      cutout: '0%',
      plugins: {
        legend: {
          display: true,
          position: 'right',
          align: 'center',
          labels: {
            color: this.mode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'black', // for dark mode
          },
        },
      },
    }
  }
}
</script>
