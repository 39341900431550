<template>
  <span
    class="c-baseloader loader"
    :class="{
      big: size === 'big',
      small: size === 'small',
    }"
  ></span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

export type IUILoaderSizes = 'big' | 'small'

@Component
export default class UIButton extends Vue {
  @Prop({ required: false, default: 'big' })
  public size!: IUILoaderSizes
}
</script>

<style scoped lang="scss">
.loader {
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.big {
  width: 60px;
  height: 60px;
}

.small {
  width: 35px;
  height: 35px;
}

.loader::before,
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #19709b;
  animation: prixClipFix 2s linear infinite;
}
.loader::after {
  border-color: #d81b60;
  animation: prixClipFix 2s linear infinite, rotate 0.5s linear infinite reverse;
  inset: 6px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
</style>
