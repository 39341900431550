export default {
  menu: {
    title: 'Menu',
    titleSettings: 'Settings',
    tickets: 'Notifications',
    tasks: 'tasks',
    calendar: 'Calendar',
    assets: 'Assets',
    users: 'Users',
    glossaries: 'Glossaries',
    companies: 'Companies',
    attributes: 'Attributes',
    parameters: 'Parameters',
    roles: 'Role',
    profileSettings: 'Account settings',
    consents: 'Consents',
    sources: 'Sources',
  },
}
