import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia'
import './index.css'
import 'material-icons/iconfont/material-icons.css'
import VueUploadComponent from 'vue-upload-component'
import { vue3Debounce } from 'vue-debounce'

import App from './App.vue'
import router from './router'
import './assets/app.scss'
import 'vue3-toastify/dist/index.css'

import pl from '@/translations/pl'
import en from './translations/en'

import BootstrapVueNext from 'bootstrap-vue-next'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faCheck,
  faLock,
  faLockOpen,
  faChevronLeft,
  faChevronDown,
  faChevronRight,
  faCalendarDays,
  faBell,
  faList,
  faLaptop,
  faUsers,
  faBook,
  faBookmark,
  faPeopleGroup,
  faUserShield,
  faGear,
  faRightFromBracket,
  faXmark,
  faPlus,
  faMinus,
  faAnglesRight,
  faAnglesLeft,
  faMagnifyingGlass,
  faFilter,
  faCircleUser,
  faArrowLeft,
  faTrash,
  faPenToSquare,
  faSquarePlus,
  faSquareMinus,
  faCircleChevronLeft,
  faCircle,
  faMoon,
  faCircleHalfStroke,
  faFire,
  faEnvelope,
  faArrowsRotate,
  faClipboardList,
  faEdit,
  faKey,
  faPlug,
  faIdBadge,
  faArrowRight,
  faCircleInfo,
  faPrint,
  faDownload,
  faFile,
  faFolder,
  faCog,
  faFileLines,
  faSitemap,
  faGears,
  faSlash,
} from '@fortawesome/free-solid-svg-icons'
/* import specific icons */
import {
  faEye,
  faRectangleList,
  faBuilding,
  faEyeSlash,
  faCircleDown,
} from '@fortawesome/free-regular-svg-icons'

import { faGoogle } from '@fortawesome/free-brands-svg-icons'

// Optional, since every component imports their Bootstrap functionality
// the following line is not necessary
// import 'bootstrap'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import UserService from '@/stores/UserService'
import PasswordStrengthInfoService from '@/stores/modals/PasswordStrengthInfoService'
import ExpiredSessionModalService from '@/stores/modals/ExpiredSessionModalService'
import TaskService from '@/stores/TaskService'
import LoaderService from '@/stores/LoaderService'
import ConsentService from '@/stores/ConsentService'
import ErrorsService from '@/stores/ErrorsService'

import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify'
import CompaniesService from '@/stores/CompaniesService'
import UnitForm from '@/components/views/companies/UnitForm.vue'
import AttributesService from '@/stores/AttributesService'
import GlossariesFormAddModalService from '@/stores/modals/GlossariesFormAddModalService'
import GlossariesAddFormKindModalService from '@/stores/modals/GlossariesAddFormKindModalService'
import ConsentsModalService from '@/stores/modals/ConsentsModalService'
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  DoughnutController,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Bar } from 'vue-chartjs'
import { Doughnut } from 'vue-chartjs'
import GlossaryService from '@/stores/GlossaryService'
import AssetsService from '@/stores/AssetsService'
import TaskAddModalService from '@/stores/modals/TaskAddModalService'
import TaskInfoModalService from '@/stores/modals/TaskInfoModalService'
import AccessContainer from '@/components/common/AccessContainer.vue'
import EnvironmentsManager from '@/stores/EnvironmentsManager'
import FileService from '@/stores/FileService'
import scrollPlugin from '@/plugins/scrollPlugin'
import ReportsService from '@/stores/ReportsService'
//eslint-disable-next-line
export const i18n = createI18n({
  locale: 'pl',
  legacy: false,
  messages: {
    en: { ...en },
    pl: { ...pl },
  },
})

library.add(
  faGoogle,
  faLock,
  faLockOpen,
  faCircleDown,
  faEye,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faBell,
  faCalendarDays,
  faList,
  faRectangleList,
  faLaptop,
  faUsers,
  faBook,
  faBuilding,
  faBookmark,
  faPeopleGroup,
  faUserShield,
  faGear,
  faPenToSquare,
  faRightFromBracket,
  faXmark,
  faPlus,
  faMinus,
  faAnglesRight,
  faAnglesLeft,
  faMagnifyingGlass,
  faFilter,
  faEyeSlash,
  faCircleUser,
  faArrowLeft,
  faArrowRight,
  faTrash,
  faSquarePlus,
  faSquareMinus,
  faCircleChevronLeft,
  faCircle,
  faMoon,
  faCircleHalfStroke,
  faFire,
  faArrowsRotate,
  faClipboardList,
  faEdit,
  faKey,
  faEnvelope,
  faPlug,
  faIdBadge,
  faCircleInfo,
  faPrint,
  faDownload,
  faFile,
  faFolder,
  faCog,
  faFileLines,
  faSitemap,
  faGears,
  faFileLines,
  faSlash
)

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
ChartJS.register(DoughnutController, ArcElement, Title, Tooltip, Legend)
const app = createApp(App)
app.use(createPinia())
app.use(scrollPlugin)
app.use(router)
app.use(i18n)
app.use(BootstrapVueNext)
app.use(Vue3Toastify, {
  autoClose: 3000,
  clearOnUrlChange: false,
  dangerouslyHTMLString: false,
} as ToastContainerOptions)
app.directive('debounce', vue3Debounce({ lock: true }))
app.component('UnitForm', UnitForm)
app.component('file-upload', VueUploadComponent)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('V-Bar', Bar)
app.component('V-Doughnut', Doughnut)
app.component('AccessContainer', AccessContainer)
app.mount('#app')

if (import.meta.env.VITE_ENV === 'development') {
  // @ts-ignore
  app.config.devtools = true
  app.config.performance = true
}

//Initialize pinia stores
export const environmentsManager = new EnvironmentsManager()
export const userService = new UserService()
export const passwordStrengthInfoService = new PasswordStrengthInfoService()
export const expiredSessionModalService = new ExpiredSessionModalService()
export const taskService = new TaskService()
export const taskAddModalService = new TaskAddModalService()
export const taskInfoModalService = new TaskInfoModalService()
export const loaderService = new LoaderService()
export const consentService = new ConsentService()
export const companiesService = new CompaniesService()
export const attributesService = new AttributesService()
export const glossariesFormAddModalService = new GlossariesFormAddModalService()
export const glossariesAddFormKindModalService =
  new GlossariesAddFormKindModalService()
export const consentsModalService = new ConsentsModalService()
export const errorsService = new ErrorsService()
export const glossaryService = new GlossaryService()
export const assetsService = new AssetsService()
export const reportsService = new ReportsService()
export const fileService = new FileService()
