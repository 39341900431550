export default {
  login: {
    title: 'Login',
    anonymousReport: 'Anonymous report',
    termsOfService: 'Terms of Service',
    error: {
      invalidCredentials: 'Invalid login credentials',
    },
  },
}
