<template>
  <b-dropdown-item>
    <span class="icon tw-mr-2 !tw-text-lg tw-text-black dark:tw-text-greyce">
      <font-awesome-icon icon="fa-solid fa-moon" />
    </span>
    <UIToggle
      :modelValue="mode === 'dark'"
      @update:model-value="handleModeToggle"
      class="tw-ml-5"
      id="darkModeToggle"
      name="darkModeToggle"
    />
  </b-dropdown-item>

  <b-dropdown-item>
    <span class="icon tw-mr-2 !tw-text-lg tw-text-black dark:tw-text-greyce">
      <font-awesome-icon icon="fa-solid fa-circle-half-stroke" />
    </span>
    <UIToggle
      :modelValue="state.mode === 'contrast'"
      @update:model-value="handleToggleContrast"
      class="tw-ml-4"
      id="toggleContrast"
      name="toggleContrast"
    />
  </b-dropdown-item>
</template>

<script setup lang="ts">
import { useColorMode, useStorage } from '@vueuse/core'
import { onMounted } from 'vue'
import UIToggle from '@/components/UI/UIToggle.vue'

const mode = useColorMode({ attribute: 'data-bs-theme' })

function handleModeToggle(): void {
  mode.value = mode.value === 'light' ? 'dark' : 'light'
}

const state = useStorage('mode', { mode: '' })

function handleToggleContrast(): void {
  const isContrastEnabled =
    document.documentElement.getAttribute('mode') === 'contrast'

  if (isContrastEnabled) {
    document.documentElement.setAttribute('mode', '')
    state.value = { mode: '' }
    return
  }

  state.value = { mode: 'contrast' }
  document.documentElement.setAttribute('mode', 'contrast')
}

onMounted(() => {
  document.documentElement.setAttribute('mode', state.value.mode)
})
</script>
