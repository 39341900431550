export default {
  button: {
    save: 'Save',
    cleanFilter: 'Clear filters',
    search: 'Search',
    apply: 'Apply',
    addApplication: 'Add application',
    addTask: 'AddTask',
    addAsset: 'Add asset',
    addCompany: 'Add Company',
    addAttribute: 'Add attribute',
    editConsent: 'Edit consent',
    show: 'Show',
    editRole: 'Edit Role',
    deactivate: 'Deactivate',
    editParameter: 'Edit parameter',
    addAttachments: 'Add attachments',
    addGlossary: 'Add glossary',
    block: 'Block',
    remove: 'Remove',
    showUserSettings: 'Show user settings',
    resetTotpAuth: 'Reset totp auth',
    back: 'Back',
    addConsent: 'Add consent',
    addFiles: 'Add files',
    cancel: 'Cancel',
    identify: 'Identyfikuj',
    identyfikacja: 'Dodaj identyfikacje',
  },
}
