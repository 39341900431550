<template>
  <div class="c-uiCheckbox tw-mt-4 tw-text-start" :class="containerClass">
    <div class="form-check ps-0 tw-relative tw-text-start">
      <input
        class="form-check-input tw-mr-0.5"
        type="checkbox"
        v-bind="$attrs"
        :disabled="disabled"
        :id="name"
        :name="name"
        :checked="modelValue"
        @change="$emit('update:modelValue', !modelValue)"
      />
      <font-awesome-icon
        v-show="modelValue"
        icon="fa-solid fa-check"
        class="cursor-pointer tw-absolute tw-left-[5px] tw-top-[6px] tw-w-[10px] tw-text-white"
        @click="toggleCheckbox"
      />
      <label
        class="form-check-label tw-relative dark:tw-text-white"
        :for="name"
        :class="labelClass"
      >
        <slot />
        <span
          v-if="isTooltip"
          v-b-tooltip.hover
          :title="tooltipText"
          class="tw-absolute -tw-right-8 -tw-top-2 tw-ml-2 tw-cursor-pointer tw-text-gray-400"
        >
          <font-awesome-icon
            icon="icon fa-solid fa-circle-info"
            class="tw-mt-2 tw-text-xl"
          />
        </span>
      </label>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({
  emits: ['update:modelValue'],
})
export default class UICheckbox extends Vue {
  @Prop()
  public modelValue: boolean

  @Prop()
  public name: string

  @Prop({ required: false })
  public labelClass: string

  @Prop({ required: false })
  public disabled: boolean

  @Prop({ required: false })
  public containerClass: string

  @Prop({ required: false, default: false })
  public isTooltip!: boolean

  @Prop({ required: false, default: '' })
  public tooltipText!: string
  public toggleCheckbox(): void {
    if (this.disabled) return
    this.$emit('update:modelValue', !this.modelValue)
  }
}
</script>
