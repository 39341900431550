<template>
  <div class="c-pendingNotifications">
    <DashboardBox
      :title="$t('views.dashboard.pendingNotifications')"
      :is-loading="isLoading"
    >
      <slot>
        <UIGridTable :headers="pendingNotificationsHeaders">
          <div
            class="tw-grid tw-cursor-pointer tw-grid-cols-4 tw-text-sm hover:tw-bg-greye5 hover:tw-duration-300 xl:tw-text-base"
            v-for="(ticket, index) in tickets"
            :key="`pendingNotification_${index}`"
            @click="redirectToTicket(ticket.id)"
          >
            <div class="tw-p-2">{{ ticket.formatted_id }}</div>
            <div class="tw-p-2">
              {{ getDateAndHourFormat(ticket.created_at) }}
            </div>
            <div class="tw-p-2">{{ ticket.status.name }}</div>
            <div class="tw-p-2">
              {{ ticket.user.first_name }} {{ ticket.user.last_name }}
            </div>
          </div>
        </UIGridTable>
      </slot>
    </DashboardBox>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import DashboardBox from '@/components/views/dashboard/DashboardBox.vue'
import UIGridTable from '@/components/UI/UIGridTable.vue'
import type { IWidgetReportsBeingResolved } from '@/types/WidgetTypes'
import { getDateAndHourFormat } from '@/helpers/getDateAndHourFormat'

@Component({
  components: {
    DashboardBox,
    UIGridTable,
  },
})
export default class PendingNotifications extends Vue {
  @Prop({ required: true })
  public isLoading!: boolean
  public get pendingNotificationsHeaders(): string[] {
    return [
      this.$t('views.dashboard.notificationID'),
      this.$t('views.dashboard.dateOfNotification'),
      this.$t('views.dashboard.status'),
      this.$t('views.dashboard.responsiblePerson'),
    ]
  }

  @Prop({ required: true })
  public tickets!: IWidgetReportsBeingResolved[]

  public get getDateAndHourFormat(): (date: string) => string {
    return getDateAndHourFormat
  }

  public redirectToTicket(id: number): void {
    this.$router.push(`/identification/preview?ticket_id=${id}`)
  }
}
</script>
