<template>
  <div
    class="c-positionsWithPagination tw-flex tw-items-baseline tw-justify-between"
  >
    <p class="tw-text-grey7b dark:tw-text-greyd2">
      {{ $t('common.positionFrom') }} {{ startPage }} {{ $t('common.to') }}
      {{ endPage }} {{ $t('common.of') }} {{ numberOfAllResults }}
      {{ $t('common.total') }}
    </p>
    <!--    <Pagination-->
    <!--        :number-of-pages="numberOfPages"-->
    <!--        :active-page="activePage"-->
    <!--        @set-page="setActivePage"-->
    <!--        v-if="numberOfPages > 1"-->
    <!--    />-->
    <Pagination
      :active-page="activePage"
      :number-of-pages="2"
      v-if="numberOfPages > 1"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Pagination from '@/components/common/Pagination.vue'

@Component({
  components: { Pagination },
})
export default class PositionsWithPagination extends Vue {
  @Prop({ required: true })
  public numberOfAllResults: number

  @Prop({ required: true })
  public numberOfResultsPerPage: number

  public activePage: number = 1
  public numberOfPages: number = 3

  public get startPage(): number {
    if (this.numberOfAllResults === 0) return 0
    if (this.activePage === 1) return 1
    return (this.activePage - 1) * this.numberOfResultsPerPage + 1
  }
  public get endPage(): number {
    if (this.numberOfResultsPerPage * this.activePage > this.numberOfAllResults)
      return this.numberOfAllResults
    return this.numberOfResultsPerPage * this.activePage
  }

  /**********************************/
  // @Prop({ required: true })
  // public numberOfAllResults!: number
  //
  // @Prop({ required: true })
  // public activePage!: number
  //
  // @Prop({ required: true })
  // public numberOfPages!: number
  //
  // @Prop({ required: true })
  // public numberOfResultsPerPage!: number
  //
  // public setActivePage(page: number): void {
  //   this.$emit('set-page', page)
  // }
  // public setNumberOfResultsPerPage(numberOfResultsPerPage: number): void {
  //   this.$emit('set-results', numberOfResultsPerPage)
  // }
  // public get startPage(): number {
  //   if (this.numberOfAllResults === 0) return 0
  //   if (this.activePage === 1) return 1
  //   return (this.activePage - 1) * this.numberOfResultsPerPage + 1
  // }
  // public get endPage(): number {
  //   if (this.numberOfResultsPerPage * this.activePage > this.numberOfAllResults)
  //     return this.numberOfAllResults
  //   return this.numberOfResultsPerPage * this.activePage
  // }
  /**********************************/
}
</script>
