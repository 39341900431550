export default {
  resetPassword: {
    anonymousReport: 'Zgłoszenie anonimowe',
    title: 'Resetowanie hasła',
    sendButton: 'Zresetuj hasło',
    termsOfService: 'Warunki korzystania z usługi\n',
    emailSent: 'Email wysłany',
    checkEmail:
      'Email z linkiem do zresetowania hasła został wysłany na Twój adres email. ',
    followInstructions:
      'Postępuj zgodnie z instrukcjami w emailu, aby zresetować hasło.',
    successReset: 'Resetowanie hasła zakończone sukcesem',
    errorReset: 'Resetowanie hasła nie powiodło się',
    resetFor: 'Resetowanie hasła dla',
  },
}
