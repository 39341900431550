<template>
  <div class="c-pagination tw-flex tw-items-center tw-text-grey55">
    <UIButton
      v-if="activePage > 1"
      @click="setPage(1)"
      button-class="tw-flex tw-items-center tw-justify-center tw-h-[32px] tw-w-[32px] tw-mx-2 tw-cursor-pointer tw-border tw-border-greyde !tw-rounded-full tw-duration-500 hover:tw-text-grey98"
      type=""
    >
      <template #right>
        <font-awesome-icon
          icon="fa-solid fa-angles-left"
          :class="{ icon: true }"
          class="tw-text-xs"
        />
      </template>
    </UIButton>
    <UIButton
      v-if="activePage > 1"
      @click="setPage(activePage - 1)"
      button-class="tw-flex tw-items-center tw-justify-center tw-h-[32px] tw-w-[32px] tw-mx-2 tw-cursor-pointer tw-border tw-border-greyde !tw-rounded-full tw-duration-500 hover:tw-text-grey98"
      type=""
    >
      <template #right>
        <font-awesome-icon
          icon="icon fa-solid fa-angles-left"
          :class="{ icon: true }"
          class="tw-text-xs"
        />
      </template>
    </UIButton>
    <div class="tw-flex tw-items-center tw-text-sm tw-font-semibold">
      <UIButton
        :button-class="[
          'tw-mx-0.5 tw-flex tw-h-[32px] tw-w-[32px] tw-cursor-pointer tw-items-center tw-justify-center !tw-rounded-full tw-duration-500',
          isActive(page) && 'tw-bg-pinkd8 tw-text-white',
          !isActive(page) &&
            'hover:tw-bg-pinkd8 hover:tw-text-white tw-border tw-border-greyde',
        ]"
        v-show="page > activePage - 3 && page < activePage + 3"
        v-for="page in numberOfPages"
        :key="page"
        @click="setPage(page)"
        type=""
        :label="page"
      >
      </UIButton>
    </div>
    <UIButton
      v-if="activePage < numberOfPages"
      @click="setPage(activePage + 1)"
      button-class="tw-flex tw-items-center tw-justify-center tw-h-[32px] tw-w-[32px] tw-mx-1.5 tw-cursor-pointer tw-border-1 tw-border-greyde !tw-rounded-full tw-duration-500 hover:tw-text-grey98"
      type=""
    >
      <template #right>
        <font-awesome-icon
          icon="icon fa-solid fa-chevron-right"
          :class="{ icon: true }"
          class="tw-text-xs"
        />
      </template>
    </UIButton>
    <UIButton
      v-if="activePage < numberOfPages"
      @click="setPage(numberOfPages)"
      button-class="tw-flex tw-items-center tw-justify-center tw-h-[32px] tw-w-[32px] tw-cursor-pointer tw-border tw-border-greyde !tw-rounded-full tw-duration-500 hover:tw-text-grey98"
      type=""
    >
      <template #right>
        <font-awesome-icon
          icon="icon fa-solid fa-angles-right"
          :class="{ icon: true }"
          class="tw-text-xs"
        />
      </template>
    </UIButton>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import UIButton from '@/components/UI/UIButton.vue'

@Component({
  components: { UIButton },
})
export default class Pagination extends Vue {
  @Prop({ required: true })
  public activePage!: number

  @Prop({ required: true })
  public numberOfPages!: number

  public isActive(page: number): boolean {
    return page === this.activePage
  }
  public setPage(page: number): void {
    this.$emit('setPage', page)
  }
}
</script>
