<template>
  <slot />
</template>
<script setup lang="ts">
// Reason for this file:
// Quill.js does not support dynamic translations out of the box.
// All the properties come from theme and are using ::after or ::before
// pseudo-selectors to display text in :content
// this is a workaround for translations to work
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t, locale } = useI18n()
// Define a reactive theme object
const theme = ref({
  header1: t('common.quill.header1'),
  header2: t('common.quill.header2'),
  header3: t('common.quill.header3'),
  header4: t('common.quill.header4'),
  header5: t('common.quill.header5'),
  header6: t('common.quill.header6'),
  normal: t('common.quill.normal'),
  insertLink: t('common.quill.insertLink'),
  save: t('common.quill.save'),
})

const setQuillVars = (): void => {
  const style = document.documentElement.style
  style.setProperty('--ql-header1', `"${theme.value.header1}"`)
  style.setProperty('--ql-header2', `"${theme.value.header2}"`)
  style.setProperty('--ql-header3', `"${theme.value.header3}"`)
  style.setProperty('--ql-header4', `"${theme.value.header4}"`)
  style.setProperty('--ql-header5', `"${theme.value.header5}"`)
  style.setProperty('--ql-header6', `"${theme.value.header6}"`)
  style.setProperty('--ql-normal', `"${theme.value.normal}"`)
  style.setProperty('--ql-insert-link', `"${theme.value.insertLink}"`)
  style.setProperty('--ql-save', `"${theme.value.save}"`)
}

// Watch for locale changes
watch(
  locale,
  (newLoc, oldLoc) => {
    if (newLoc !== oldLoc) {
      setQuillVars()
    }
  },
  { deep: true }
)

// set default vals on mount
onMounted(() => {
  setQuillVars()
})
</script>

// Defaults to be overwritten on mount - in case something goes wrong
<style>
:root {
  --ql-header1: 'Heading 1';
  --ql-header2: 'Heading 2';
  --ql-header3: 'Heading 3';
  --ql-header4: 'Heading 4';
  --ql-header5: 'Heading 5';
  --ql-header6: 'Heading 6';
  --ql-normal: 'Normal';
  --ql-insert-link: 'Insert Link';
  --ql-save: 'Save';
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
  content: var(--ql-header1) !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
  content: var(--ql-header2) !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
  content: var(--ql-header3) !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
  content: var(--ql-header4) !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
  content: var(--ql-header5) !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
  content: var(--ql-header6) !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: var(--ql-normal) !important;
}
.ql-snow .ql-tooltip[data-mode='link']::before {
  content: var(--ql-insert-link) !important;
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: var(--ql-save) !important;
}
</style>
