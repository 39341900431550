<template>
  <div class="c-fieldsFromGlossaries tw-mt-8" v-if="form.length > 0">
    <GlossaryForm
      class="tw-mb-3 tw-grid tw-grid-cols-1 tw-gap-3 xl:tw-mb-6 xl:tw-grid-cols-2 xl:tw-gap-6"
      :disabled="disabled"
      :initialForm="form"
      @saveItem="saveFieldForm"
      @setLastElement="lastElement = $event"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import GlossaryForm from '@/components/common/GlossaryForm.vue'
import { eventBus } from '@/helpers/EventBus'
import type {
  IGlossaryFieldType,
  IGlossaryHeader,
  IGlossaryHeaderLine,
  IGlossaryLineTicket,
  IGlossaryTicketLineAddForm,
  IGlossaryTicketLineUpdateForm,
} from '@/types/GlossariesTypes'
import { assetsService, glossaryService, userService } from '@/main'
import type {
  IFormItem,
  IItemOption,
  TItemType,
} from '@/types/GlossaryFormTypes'
import type { Nullable } from '@/types/Nullable'
import type { SelectOption } from '@/types/CommonTypes'
import type { IAsset } from '@/types/AssetsTypes'
import type { IUserRelations } from '@/types/UserTypes'
import glossariesRepository from '@/repositories/GlossariesRepository'

@Component({
  components: {
    GlossaryForm,
  },
})
export default class GlossariesAssetForm extends Vue {
  @Prop({ required: true, default: false })
  public disabled!: boolean

  public isLoading: boolean = false

  public lines: IGlossaryLineTicket[] = []

  public form: IFormItem[] = []

  public lastElement: Nullable<HTMLElement> = null

  public changeEvents: string[] = [
    'glossaryInputChanged',
    'glossarySelectChanged',
    'glossaryTextAreaChanged',
    'glossaryCheckboxChanged',
    'glossaryRadioChanged',
  ]

  public scrollToLastElement(): void {
    setTimeout(() => {
      if (this.lastElement) {
        this.$scrollToElement(this.lastElement)
      }
    }, 300)
  }

  public get asset(): Nullable<IAsset> {
    return assetsService.asset
  }

  public get user(): Nullable<IUserRelations> {
    return userService.getUser
  }

  public get ticketId(): Nullable<number> {
    return this.asset?.ticket_id ?? null
  }

  public get fieldTypes(): IGlossaryFieldType[] {
    return glossaryService.fieldTypes
  }

  public get childrenHeaders(): IGlossaryHeader[] {
    return glossaryService.childrenHeaders
  }

  public getNewIndex(): number {
    return this.lines.length + 1
  }

  public getFieldType(type: number): TItemType {
    const typeEl = this.fieldTypes.find((fieldType) => fieldType.id === type)
    return (typeEl?.name ?? 'input') as TItemType
  }

  public prepareForm(): IFormItem[] {
    const withOutNullable = this.lines.filter((el) => el !== null)
    const items = withOutNullable.map((ticketLine) => {
      return {
        id: ticketLine.id,
        type: this.getFieldType(ticketLine.header!.field_type),
        name: ticketLine.header!.name,
        description: ticketLine.header!.description,
        index: ticketLine.index,
        is_end: ticketLine.line!.end,
        value: ticketLine.line!.id ?? ticketLine.description,
        inputValue: ticketLine.description,
        options: this.prepareItemOptions(
          ticketLine.header!.lines,
          ticketLine.line!.id
        ),
        selectOptions: this.prepareSelectOptions(ticketLine.header!.lines),
      }
    })

    const isEnd = this.lines.some((item) => item.line.end === 1)
    if (!isEnd) {
      const nextItem = this.getChildItem()
      if (nextItem) {
        items.push(nextItem)
      }
      this.isEnd = false
    } else {
      this.isEnd = true
    }
    return items
  }

  public prepareSelectOptions(lines: IGlossaryHeaderLine[]): SelectOption[] {
    return lines.map((line) => {
      return {
        value: line.id,
        label: line.name,
        id: line.id,
      }
    })
  }

  public prepareItemOptions(
    lines: IGlossaryHeaderLine[],
    checkedId: number
  ): IItemOption[] {
    return lines.map((line) => {
      return {
        id: line.id,
        value: line.name,
        is_end: line.end === 1,
        next_id: line.child_header_by_number?.header_number ?? null,
        checked: line.id === checkedId,
        description: line.description ?? '',
      }
    })
  }

  public getChildItem(): Nullable<IFormItem> {
    if (!this.childrenHeaders || this.childrenHeaders.length === 0) {
      return null
    }

    const header = this.childrenHeaders[0]

    return {
      id: null,
      type: this.getFieldType(header.field_type),
      name: header.name,
      description: header.description,
      is_end: false,
      value: '',
      inputValue: '',
      options: this.prepareItemOptions(header!.lines, -1),
      selectOptions: this.prepareSelectOptions(header!.lines),
    }
  }

  public async loadLines(): Promise<void> {
    if (this.ticketId) {
      this.lines = await glossariesRepository
        .getGlossaryLinesForTicket(this.ticketId)
        .then(async (re) => {
          const response = re.filter((line) => line !== null)
          if (response.length === 0) {
            return await this.loadChildLines(1).then(() => {
              return []
            })
          }
          const isEnd = response.some((line) => line.line?.end === 1)
          if (isEnd) return response
          const lastLine = response[response.length - 1]
          return await this.loadChildLines(
            lastLine.line.child_header_number
          ).then(() => {
            return response
          })
        })
    }
  }

  public async updateField(form: IGlossaryTicketLineUpdateForm): Promise<void> {
    await glossaryService
      .updateGlossaryLineTicket(this.ticketId, form)
      .catch(() => {
        console.error('Error updating glossary line ticket')
      })
  }

  public async addField(form: IGlossaryTicketLineAddForm): Promise<void> {
    await glossaryService
      .addGlossaryLineTicket(this.ticketId, form)
      .catch((e) => {
        console.error('Error adding glossary line ticket', e)
      })
  }

  public async loadChildLines(parentId): Promise<void> {
    await glossaryService.loadGlossaryFormChildrenHeaders({
      formId: 3,
      parentHeaderId: parentId,
    })
  }

  public async saveFieldForm(response): Promise<void> {
    this.$emit('saveItem')
    if (this.isLoading) return
    this.isLoading = true
    let description = ''
    let value = null
    if (response.item.type === 'input' || response.item.type === 'textArea') {
      description = response.item.inputValue
      if (response.item.options && response.item.options.length > 0) {
        value = response.item.options[0].id
      }
    } else {
      value = response.value
    }

    if (response.item.id) {
      await this.updateField({
        id: response.item.id,
        line_id: Number(value),
        description: description,
      }).then(async () => {
        await this.loadLines().then(() => {
          this.form = this.prepareForm()
          this.scrollToLastElement()
          this.isLoading = false
        })
      })
    } else {
      const lastItem = this.childrenHeaders[0]
      await this.addField({
        line_id: Number(value),
        description: description,
        form_id: 3,
        header_id: lastItem.id,
        ticket_id: this.ticketId,
        index: this.getNewIndex(),
      }).then(async () => {
        await this.loadLines().then(() => {
          this.form = this.prepareForm()
          this.scrollToLastElement()
          this.isLoading = false
        })
      })
    }
  }

  public mountEvents(): void {
    this.changeEvents.forEach((event) => {
      eventBus.on(event, this.updateForm)
    })
    // this.$emit('endForm', this.isEndForm)
    eventBus.on('endForm', (value) => this.$emit('endForm', value))
    //eventBus.on('saveItem', this.saveFieldForm)
  }

  public clearEvents(): void {
    this.changeEvents.forEach((event) => {
      eventBus.off(event)
    })
    eventBus.off('endForm')
    eventBus.off('saveItem')
  }

  public created(): void {
    this.clearEvents()
    this.mountEvents()
  }

  public async mounted(): Promise<void> {
    await glossaryService.loadGlossaryFieldTypes()
    await this.loadLines().then(() => {
      this.form = this.prepareForm()
      this.scrollToLastElement()
    })
  }

  public destroyed(): void {
    this.clearEvents()
  }
}
</script>
