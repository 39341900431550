<template>
  <div class="c-uiGridTable tw-relative tw-p-4">
    <div class="tw-overflow-auto">
      <div class="tw-w-max tw-min-w-full">
        <header
          :style="{ 'grid-template-columns': gridTemplateColumns }"
          :class="headerClass"
        >
          <div
            class="tw-p-2 tw-text-sm xl:tw-text-base"
            v-for="(header, index) in headers"
            :key="index"
          >
            {{ header }}
          </div>
        </header>

        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component
export default class UIGridTable extends Vue {
  @Prop({ required: true })
  public headers!: string[]

  @Prop({ default: '' })
  public gridTemplateColumns!: string

  public get headerClass(): string {
    return `tw-grid tw-grid-cols-${this.headers.length} tw-border-b-2 tw-border-b-greyce tw-bg-greye5 tw-font-bold tw-text-black`
  }
}
</script>
