import client from '@/services/client'
import { isAxiosError } from 'axios'
import { errorsService } from '@/main'
import { errorsEnum } from '@/types/ErrorTypes'
import type {
  FiltersForm,
  ReportForm,
  ReportsDefinition,
} from '@/types/ReportsTypes'

class ReportsRepository {
  public async getReportsDefinitions(): Promise<ReportsDefinition[]> {
    return await client
      .get(`reports/definitions`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.ReportsDefinitionGet,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getReportsDefinitionsExtensions(
    report_type: string = 'ticket_list'
  ): Promise<string[]> {
    return await client
      .get(`reports/${report_type}/formats`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.ReportsDefinitionFormatsGet,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async addReport(
    form: ReportForm,
    filters?: FiltersForm
  ): Promise<string[]> {
    let query = ''
    if (filters && (filters.filterBy || filters.orderBy || filters.searchBy)) {
      if (filters.filterBy) {
        query += `?`
        query += `&filterBy=[[{"${filters.filterBy.key}":"${filters.filterBy.value}"}]]`
      }

      if (filters.orderBy) {
        if (filters.filterBy) {
          query += `&`
        } else {
          query += `?`
        }
        query += `orderBy=[[{"${filters.orderBy.key}":"${filters.orderBy.value}"}]]`
      }

      if (filters.searchBy) {
        if (filters.filterBy || filters.orderBy) {
          query += `&`
        } else {
          query += `?`
        }
        query += `searchBy=[[{"${filters.searchBy.key}":"${filters.searchBy.value}"}]]`
      }
    }
    return await client
      .post(`reports${query}`, form)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.ReportsDefinitionFormatsGet,
            response: error.response,
          })
        }
        throw error
      })
  }
}

export default new ReportsRepository()
